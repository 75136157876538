import React from 'react';
import { Box, Typography, Button, TextField, Link } from '@material-ui/core';
import useStyles from './UserProfileStyles'; // Adjust the path as necessary

const ProfileDetails = ({ profile, onCvUpload, onFieldChange, editMode }) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Typography variant="h6" className={classes.profileHeader}>Детали профиля:</Typography>
            {editMode ? (
                <>
                    <Button
                        variant="contained"
                        component="label"
                        className={classes.button}
                    >
                        Загрузить CV (.pdf)
                        <input
                            id="cvUpload"
                            type="file"
                            accept=".pdf"
                            onChange={onCvUpload}
                            hidden
                        />
                    </Button>
                    <TextField 
                        label="Профессия"
                        variant="outlined"
                        name="jobTitle"
                        value={profile.jobTitle || ''}
                        onChange={onFieldChange('profile', 'jobTitle')}
                        fullWidth
                        className={classes.formControl}
                    />
                    <TextField 
                        label="Ставка в день (руб)"
                        variant="outlined"
                        name="dailyRate"
                        value={profile.dailyRate || ''}
                        onChange={onFieldChange('profile', 'dailyRate')}
                        fullWidth
                        className={classes.formControl}
                    />
                    <TextField 
                        label="Стаж, лет"
                        variant="outlined"
                        name="experienceLevel"
                        value={profile.experienceLevel || ''}
                        onChange={onFieldChange('profile', 'experienceLevel')}
                        fullWidth
                        className={classes.formControl}
                    />
                    {profile.cvUrl && 
                        <Typography variant="body1" className={classes.cvContainer}>
                            <Link href={profile.cvUrl} target="_blank" rel="noopener noreferrer" className={classes.viewLink}>
                                Посмотреть CV
                            </Link>
                        </Typography>
                    }
                </>
            ) : (
                <>
                    <Typography variant="body1">Профессия: {profile.jobTitle}</Typography>
                    <Typography variant="body1">Ставка в день (руб): {profile.dailyRate}</Typography>
                    <Typography variant="body1">Стаж, лет: {profile.experienceLevel}</Typography>
                    {profile.cvUrl && 
                        <Typography variant="body1" className={classes.cvContainer}>
                            CV (резюме): 
                            <Link href={profile.cvUrl} target="_blank" rel="noopener noreferrer" className={classes.viewLink}>
                                Посмотреть CV
                            </Link>
                        </Typography>
                    }
                </>
            )}
        </Box>
    );
};

export default React.memo(ProfileDetails);
