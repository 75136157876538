import React, {
  createContext,
  useContext,
  useReducer,
} from "react";
import { useStateValue } from "../StateProvider"; // Adjust the import path as necessary

export const ChatContext = createContext();

export const ChatContextProvider = ({ children }) => {
  const [{ user: currentUser }] = useStateValue(); // Destructure to get currentUser from StateProvider
  const INITIAL_STATE = {
    chatId: null, // Note: null, not "null"
    user: {},
  };

  const chatReducer = (state, action) => {
    switch (action.type) {
      case "CHANGE_USER":
        const newChatId =
          currentUser.uid > action.payload.uid
            ? currentUser.uid + action.payload.uid
            : action.payload.uid + currentUser.uid;
        
        console.log(`New chatId: ${newChatId}`); // Debugging
        return {
          ...state,
          user: action.payload,
          chatId: newChatId,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(chatReducer, INITIAL_STATE);

  return (
    <ChatContext.Provider value={{ data: state, dispatch }}>
      {children}
    </ChatContext.Provider>
  );
};
