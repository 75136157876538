import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { createUserWithEmailAndPassword, sendEmailVerification, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { TextField, Typography, Container, Grid, Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { auth } from '../../firebase';

const CompanySignup = () => {
  // State for company details
  const [companyName, setCompanyName] = useState('');
  const [companySize, setCompanySize] = useState('');
  const [industry, setIndustry] = useState('');
  const [location, setLocation] = useState('');
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const db = getFirestore();

  const isFormValid = () => {
    return companyName && companySize && industry && location && email && password && agreedToTerms;
  };

  // const isGoogleValid = () => {
  //   return companyName && companySize && industry && location && agreedToTerms;
  // };

  const sendGAEvent = ({ action, category, label, value }) => {
    if (window.gtag) {
      window.gtag('event', action, {
        'event_category': category,
        'event_label': label,
        'value': value
      });
    }
  };

  const registerCompany = async (userData) => {
    const companyProfile = {
      accountType: "company",
      companyInfo: {
        name: companyName,
        size: companySize,
        industry: industry,
        location: location
      },
      timestampCreated: new Date().toISOString(),
      ...userData
    };

    try {
      await setDoc(doc(db, "users", userData.uid), companyProfile);
      navigate('/login'); // Navigate to the dashboard after signup
    } catch (error) {
      setError(error.message);
    }
  };

  const createCompanyAccountWithEmail = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await sendEmailVerification(userCredential.user);
      setError("Письмо с подтверждением было отправлено. Пожалуйста, подтвердите адрес электронной почты, прежде чем продолжить.");
      registerCompany({ uid: userCredential.user.uid });
      sendGAEvent({
        category: 'Company Signup',
        action: 'Registered with Email',
        label: 'Email Registration'
      });
    } catch (error) {
      setError(error.message);
      sendGAEvent({
        category: 'Company Signup',
        action: 'Email Registration Failed',
        label: error.message
      });
    }
  };

  // const handleGoogleSignUp = async () => {
  //   try {
  //     const provider = new GoogleAuthProvider();
  //     const result = await signInWithPopup(auth, provider);
  //     registerCompany({ uid: result.user.uid });
  //   } catch (error) {
  //     setError(error.message);
  //   }
  // };


  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (isFormValid()) {
      createCompanyAccountWithEmail();
      sendGAEvent({
        category: 'Company Signup',
        action: 'Attempted Form Submission',
        label: 'Form Submission'
      });
    }
  };

  const termsAgreementCheckbox = (
    <FormControlLabel
      control={
        <Checkbox
          checked={agreedToTerms}
          onChange={(e) => setAgreedToTerms(e.target.checked)}
          name="agreedToTerms"
          color="primary"
        />
      }
      label={
        <>
          Я прочитал и принимаю{" "}
          <a href="https://firebasestorage.googleapis.com/v0/b/consultingx-d3b3a.appspot.com/o/public%2FCovista_privacy_policy.pdf?alt=media&token=c9a1415e-1633-469d-9a1c-ffc13f6dee32" target="_blank" rel="noopener noreferrer">
            Правила политики обработки персональных данных
          </a>
        </>
      }
      />
  );

  return (
    <Container maxWidth="sm" style={{ marginTop: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Регистрация Компании
      </Typography>
      <form onSubmit={handleFormSubmit}>
        <Grid container spacing={2}>
        <Grid item xs={12}>
            <TextField 
              label="Название Компании" 
              type="text" 
              value={companyName} 
              onChange={(e) => setCompanyName(e.target.value)} 
              fullWidth 
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              label="Размер Компании, чел" 
              type="text" 
              value={companySize} 
              onChange={(e) => setCompanySize(e.target.value)} 
              fullWidth 
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              label="Индустрия/отрасль" 
              type="text" 
              value={industry} 
              onChange={(e) => setIndustry(e.target.value)} 
              fullWidth 
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              label="Город" 
              type="text" 
              value={location} 
              onChange={(e) => setLocation(e.target.value)} 
              fullWidth 
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              label="Email (email регистрация)" 
              type="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              fullWidth 
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              label="Пароль (email регистрация)" 
              type="password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              fullWidth 
            />
        </Grid>
      </Grid>
      <>
  {termsAgreementCheckbox}
  {error && <Typography color="error">{error}</Typography>}
  <Button
    disabled={!isFormValid()}
    type="submit"
    variant="contained"
    color="primary"
    fullWidth
    style={{
      backgroundColor: '#4285F4',
      color: 'white',
      padding: '10px 20px',
      border: 'none',
      borderRadius: '5px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '10px',
    }}
  >
    Регистрация с Email
  </Button>
  {/* <Button
    onClick={handleGoogleSignUp}
    disabled={!isGoogleValid()}
    style={{
      backgroundColor: '#fff',
      color: '#4285F4',
      padding: '10px 20px',
      border: '1px solid #4285F4',
      borderRadius: '5px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <img
      src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
      alt="Google"
      style={{ width: '20px', marginRight: '10px' }}
    />
    Google Регистрация
  </Button> */}
</>

    </form>
  </Container>
);
}

export default CompanySignup;
