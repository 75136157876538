import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { Switch, Button } from '@material-ui/core';
import { useStateValue } from '../../StateProvider';
import Menus from '../Menus';
import './UserSettings.css';


const UserSettings = () => {
    const [{ user }] = useStateValue();
    const [settings, setSettings] = useState({
      showProfile: true,
      enableNotifications: true,
      darkMode: false,
    });
    const [saveMessage, setSaveMessage] = useState('');
  
    useEffect(() => {
        const fetchSettings = async () => {
          if (user) {
            const userRef = doc(db, 'users', user.uid);
            const docSnap = await getDoc(userRef);
      
            if (docSnap.exists() && docSnap.data().settings) {
              setSettings(docSnap.data().settings.privacySettings || settings);
            }
          }
        };
      
        fetchSettings();
      }, [user]);      
  
    const handleSettingChange = (event) => {
      const { name, checked } = event.target;
      setSettings(prevSettings => ({ ...prevSettings, [name]: checked }));
    };
  
    const handleSaveSettings = async () => {
      if (!user) {
        console.error("No user is authenticated to update settings");
        return;
      }

      try {
        const userRef = doc(db, 'users', user.uid);
        await updateDoc(userRef, {
          "settings.privacySettings": settings, // Using dot notation for nested fields
        });
        setSaveMessage("Спасибо, изменения сохранены"); // Set the save message on successful update
        setTimeout(() => setSaveMessage(''), 3000); // Clear the message after 3 seconds
      } catch (error) {
        console.error("Error saving settings: ", error);
        setSaveMessage("Ошибка сохранения изменений"); // Set an error message
        setTimeout(() => setSaveMessage(''), 3000); // Clear the message after 3 seconds
      }
    };

      
  
    return (
        <div className="dashboard">
        <Menus />
            <div className="settingsContainer">
        <h1>Пользовательские настройки</h1>
        <div className="settingsItem">
            <label>
            <span>Открыть профиль: </span>
            <Switch
                checked={settings.showProfile}
                onChange={handleSettingChange}
                name="showProfile"
            />
            </label>
        </div>
        <div className="settingsItem">
            <label>
            <span>Включить уведомления: </span>
            <Switch
                checked={settings.enableNotifications}
                onChange={handleSettingChange}
                name="enableNotifications"
            />
            </label>
        </div>
        <div className="settingsItem">
            <label>
            <span>Ночной режим: </span>
            <Switch
                checked={settings.darkMode}
                onChange={handleSettingChange}
                name="darkMode"
            />
            </label>
        </div>
        <Button
            onClick={handleSaveSettings}
            color="primary"
            variant="contained"
            className="saveButton"
        >
            Сохранить
        </Button>
        {saveMessage && <div className="saveMessage">{saveMessage}</div>} {/* Display the save message */}
        </div>
    </div>
    );
  };
  
  export default UserSettings;