import React from 'react';
import '../ComingSoon.css'; // Make sure to create a corresponding CSS file

function InviteFriend() {
    return (
        <div className="comingSoon">
            <h1>Скоро будет</h1>
            <p>Мы работаем над этой страницей. Скоро она появится. Спасибо за терпение!</p>
        </div>
    );
};

export default InviteFriend;