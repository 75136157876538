import React, { useState, useEffect } from 'react';
import Project from './Project';
import Menus from '../user_admin/Menus';
import { db } from '../firebase'; // Import your Firestore instance
import { collection, query, getDocs, orderBy, startAfter, limit } from 'firebase/firestore';
import { useStateValue } from '../StateProvider'; // Import the custom hook
import ProjectDetailsBanner from './ProjectDetailsBanner'; 
import ProposalUploadBanner from './ProposalUploadBanner';
import './ProjectsList.css';


function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

function ProjectsList() {
    const [projects, setProjects] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [loading, setLoading] = useState(false);
    const [{ user }, dispatch] = useStateValue();
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [uploadBannerProjectId, setUploadBannerProjectId] = useState(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const trackPageView = (url) => {
        if (window.gtag) {
            window.gtag('config', 'GA_MEASUREMENT_ID', {
                'page_path': url
            });
        }
    };

    // Function to handle showing project details
    const showDetails = (projectId) => {
        setSelectedProjectId(projectId);
    };

    // Function to hide project details
    const hideDetails = () => {
        setSelectedProjectId(null);
    };

    // Function to handle showing upload banner
    const showUploadBanner = (projectId) => {
        setUploadBannerProjectId(projectId);
    };

    // Function to hide upload banner
    const hideUploadBanner = () => {
        setUploadBannerProjectId(null);
    };

    const handleProposalApplied = () => {
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 5000);
    };

    const fetchProjects = async () => {
        setLoading(true);
        try {
            // Create a query against the collection.
            const projectsRef = collection(db, 'projects');
            let queryRef = query(projectsRef, orderBy("timestamp"), limit(25));
            if (lastVisible) {
                queryRef = query(projectsRef, orderBy("timestamp"), startAfter(lastVisible), limit(25));
            }
            
            const querySnapshot = await getDocs(queryRef);
            const projectsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            
            // Set the last visible document for the next query
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length-1]);
            setProjects(prevProjects => shuffleArray([...prevProjects, ...projectsData]));
        } catch (error) {
            console.error("Error fetching projects: ", error);
            // Handle errors here
        }
        setLoading(false);
    };

    useEffect(() => {
        if (user) { // Only fetch projects if there is an authenticated user
            fetchProjects();
            trackPageView('/projects-list'); // Track page view when component mounts
        }
    }, [user]);

    const loadMoreProjects = () => {
        fetchProjects();
    };

    return (
        <div className="dashboard">
            <Menus />
            <div className="projectsList">
                {projects.map((project) => (
                    <Project
                        key={project.id}
                        project={project}
                        onShowDetails={showDetails}
                        onShowUploadBanner={showUploadBanner} // New function to show upload banner
                        context={project.context}
                    />
                ))}
                {loading && <div>Loading...</div>}
                {!loading && projects.length === 0 && <div>No projects found.</div>}
                <button onClick={loadMoreProjects} disabled={loading}>Посмотреть больше проектов</button>
            </div>
            {selectedProjectId && (
                <ProjectDetailsBanner
                    projectId={selectedProjectId}
                    onClose={hideDetails}
                />
            )}
        {showSuccessMessage && (
                <div className="successMessage">
                    Ваше предложение отправлено клиенту. При наличии дополнительных вопросов, он свяжется с вами через личные сообщения.
                </div>
            )}
        {uploadBannerProjectId && (
            <ProposalUploadBanner
                projectId={uploadBannerProjectId}
                userId={user?.uid}
                onClose={hideUploadBanner}
                onApplied={handleProposalApplied} // Pass the function here
            />
        )}
        </div>
    );
}

export default ProjectsList;