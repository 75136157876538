// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore'; // Include doc and getDoc here
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getStorage } from 'firebase/storage';


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBrHg48f3AoDRIm9dbeypqyIMIiZS2sfWY",
  authDomain: "consultingx-d3b3a.firebaseapp.com",
  databaseURL: "https://consultingx-d3b3a-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "consultingx-d3b3a",
  storageBucket: "consultingx-d3b3a.appspot.com",
  messagingSenderId: "63463458890",
  appId: "1:63463458890:web:35d87222ad4a8035fefeb3",
  measurementId: "G-DCMY5EJ1RE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const db = getFirestore(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const storage = getStorage(app);

// Export for use in your components
export { db, auth, provider, doc, getDoc, setDoc, getFirestore, storage }; // Include storage in the export
