import React from 'react';
import './Header.css';
import { Link } from 'react-router-dom';

function Header() {
    return (
        <div className="header">
            <div className="header__left">
                <Link to="https://www.covista.ru/">
                    <img
                        className="header__logo"
                        src="https://firebasestorage.googleapis.com/v0/b/consultingx-d3b3a.appspot.com/o/public%2F2.png?alt=media&token=63a7a7b9-1416-4213-98c2-74f9dfdc66bb"
                        alt="logo"
                    />
                </Link>
            </div>

            <div className="header__right">
                <h4><Link to="/login">Вход</Link></h4>
                <h4><Link to="/signup">Регистрация </Link></h4>
            </div>

        </div >
    )
}

export default Header
