import React from 'react';
import {
  Container,
  Typography,
  FormControl,
  InputLabel,
  Input,
  Button,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import './PaymentDetails.css';

const PaymentDetails = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Container>
    <Typography variant={isMobile ? 'h5' : 'h2'}>Платежные данные</Typography>
    <FormControl>
        <InputLabel htmlFor="creditCardNumber">Номер карты</InputLabel>
        <Input
          type="text"
          id="creditCardNumber"
          name="creditCardNumber"
          disabled
        />
      </FormControl>
      <Typography variant="body2">
        В настоящее время функция оплаты для вас недоступна.
      </Typography>
      <Typography variant="body2">
        Мы добавим ее для вас после валидации аккаунта.
      </Typography>
      <Button variant="contained" color="primary">
        Подтвердить
      </Button>
    </Container>
  );
};

export default PaymentDetails;
