import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    display: 'flex',        // Enable Flexbox
    flexDirection: 'column', // Stack children vertically
    alignItems: 'center',    // Center horizontally
    justifyContent: 'center', // Center vertically
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(3),
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    marginBottom: theme.spacing(2),
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  backButton: {
    marginBottom: theme.spacing(2),
  },
  profileHeader: {
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  skillsChip: {
    margin: theme.spacing(0.5),
  },
  profileDetails: {
    marginBottom: theme.spacing(3),
  },
  uploadInput: {
    display: 'none',
  },
  cvContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(2, 0),
  },
  uploadLabel: {
    display: 'block',
    padding: theme.spacing(1, 0),
  },
  viewLink: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },

  outerBox: {
    border: `2px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
  },
  innerBox: {
    border: `2px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // Adjust width as necessary, or use breakpoints for responsiveness
    width: 'calc(100% - 48px)', // 24px padding on each side
  },
  // Add other styles as needed
}));

export default useStyles;
