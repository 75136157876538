import React, { useEffect, useState } from 'react';
import './Menus.css';
import { Link, useNavigate } from 'react-router-dom';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'; // Changed from DesktopWindowsIcon
import SmsIcon from '@material-ui/icons/Sms';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AssessmentIcon from '@material-ui/icons/Assessment'; // New icon for analytics
import { Avatar } from '@material-ui/core';
import { useStateValue } from '../StateProvider';
import { auth } from '../firebase';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { actionTypes } from '../reducer';

function CompanyMenus() {
    const [{ user, personalInfo }, dispatch] = useStateValue();
    const [avatarUrl, setAvatarUrl] = useState("fallback_image_url");
    const history = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const fetchImageURL = async (imageRefPath) => {
        const storage = getStorage();
        const imageRef = ref(storage, imageRefPath);
        
        try {
            const url = await getDownloadURL(imageRef);
            return url;
        } catch (error) {
            return "fallback_image_url"; // Fallback URL in case of an error
        }
    };

    useEffect(() => {
        if (personalInfo?.photoURL) {
            fetchImageURL(personalInfo.photoURL).then(url => {
                setAvatarUrl(url);
            });
        }
    }, [personalInfo?.photoURL]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const handleProfileClick = () => {
        handleClose();
        navigate('/companyprofile');
    };

    const handleAuthentication = async () => {
        if (user) {
          await auth.signOut();
          dispatch({
            type: actionTypes.SET_USER,
            user: null,
            accountType: null,
            personalInfo: null,
          });
      
          // Clear account type from localStorage
          localStorage.removeItem('accountType');
      
          history("/login");
        }
      };

      return (
        <div className="menus">
            <div className="menus__top">
                <div className="menus__top--left">
                    {/* Your logo here */}
                    <Link to="/companydashboard">
                        <img
                            className="header__logo"
                            src="https://firebasestorage.googleapis.com/v0/b/consultingx-d3b3a.appspot.com/o/public%2F2.png?alt=media&token=5ecf2548-8305-4c93-8b5f-dc09ab89d259"
                            alt="logo"
                        />
                    </Link>
                    <BusinessCenterIcon /><h5><Link to="/manage-projects">Projects</Link></h5>
                    <SmsIcon /><h5><Link to="/chat">Сообщения</Link></h5>
                    <AssessmentIcon /><h5><Link to="/analytics">Analytics</Link></h5>
                    <NotificationsNoneIcon /><h5>Notifications</h5>
                    <button><a href="/create-project">Create Project</a></button>
                </div>

                <div className="menus__top--right">
                    <Avatar />
                    <span>
                        <h4>{personalInfo?.name || 'Company'}</h4>
                    </span>
                    <ExpandMoreIcon
                        aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}
                    />
                    <Menu
                        id="fade-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                    >
                        <MenuItem onClick={handleProfileClick}>Company Profile</MenuItem>
                        <MenuItem onClick={handleClose}>Settings</MenuItem>
                        <MenuItem onClick={handleAuthentication}>Logout</MenuItem>
                    </Menu>
                </div>
            </div>
        </div>
    );
}

export default CompanyMenus;
