import React from 'react';
import { Avatar, Button, TextField, Typography, Box } from '@material-ui/core';
import useStyles from './UserProfileStyles'; // Adjust the path as necessary

const UserProfileInfo = ({ personalInfo, onPhotoUpload, onFieldChange, editMode }) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box className={classes.avatarContainer}>
                <Avatar 
                    src={personalInfo.photoURL} 
                    alt="User" 
                    className={classes.avatar}
                />
                {editMode && (
                    <Button
                        variant="contained"
                        component="label"
                        className={classes.button}
                    >
                        Обновить фото
                        <input
                            type="file"
                            accept="image/*"
                            onChange={onPhotoUpload}
                            hidden
                        />
                    </Button>
                )}
            </Box>
            {editMode ? (
                <>
                    <TextField
                        label="Имя"
                        variant="outlined"
                        name="firstName"
                        value={personalInfo?.firstName || ''}
                        onChange={onFieldChange('personalInfo', 'firstName')}
                        fullWidth
                        className={classes.formControl}
                    />
                    <TextField 
                        label="Фамилия"
                        variant="outlined"
                        name="lastName"
                        value={personalInfo.lastName || ''}
                        onChange={onFieldChange('personalInfo', 'lastName')}
                        fullWidth
                        className={classes.formControl}
                    />
                    <TextField 
                        label="Город"
                        variant="outlined"
                        name="city"
                        value={personalInfo?.address?.city || ''}
                        onChange={onFieldChange('personalInfo', 'address', 'city')}
                        fullWidth
                        className={classes.formControl}
                    />
                    <TextField 
                        label="Страна"
                        variant="outlined"
                        name="country"
                        value={personalInfo?.address?.country || ''}
                        onChange={onFieldChange('personalInfo', 'address', 'country')}
                        fullWidth
                        className={classes.formControl}
                    />
                </>
            ) : (
                <>
                    <Typography variant="h6" className={classes.profileHeader}>
                        {personalInfo.firstName} {personalInfo.lastName}
                    </Typography>
                    <Typography variant="body1">
                        {personalInfo.address.city}, {personalInfo.address.country}
                    </Typography>
                </>
            )}
        </Box>
    );
};

export default React.memo(UserProfileInfo);

