import React from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

// Define your styles here similar to your login form styles
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    // ... more styles here
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  // ... any other classes from login form
}));

function Signup() {
  const classes = useStyles();
  let navigate = useNavigate();

  const navigateToSignupType = (type) => {
      navigate(`/${type}-signup`);
  };

  return (
    <Container component="main" maxWidth="xs" className={classes.root}>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h4">Регистрация</Typography>
        <div className={classes.form}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => navigateToSignupType('freelancer')}
          >
            Создать профиль консультанта
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => navigateToSignupType('company')}
          >
            Найти консультанта
          </Button>
        </div>
        <Link href="/login" variant="body2" className={classes.link}>
          Уже есть аккаунт? Войдите
        </Link>
      </Paper>
    </Container>
  );
}

export default Signup;
