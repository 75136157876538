import React from 'react';
import './DashboardContent.css';
import { Link } from 'react-router-dom';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
// import WorkIcon from '@material-ui/icons/Work';
import TimerIcon from '@material-ui/icons/Timer';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

function CompanyDashboardContent() {
    const classes = useStyles();

    return (
        <div className="companyDashboardContent">
            {/* Collaboration Best Practices Card */}
            <div className="dashboardContent__card">
                <span>
                    <h4>Discover Collaboration Best Practices</h4>
                    <p>Explore effective strategies for teamwork and share them with your colleagues to enhance productivity.</p>
                    <button>Learn More <ArrowRightAltIcon /></button>
                </span>
                {/* <span>
                    <img src="https://example.com/path-to-your-collaboration-image.png" alt="Collaboration" />
                </span> */}
            </div>
    
            {/* Manage Projects */}
            <div className="dashboardContent__projects">
                <div className="dashboardContent__projects--heading">
                    <h3>Active Projects</h3>
                </div>
                <div className="dashboardContent__projects--table">
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Project Name / Request</TableCell>
                                    <TableCell align="right">Entries</TableCell>
                                    <TableCell align="right">Average Rate/Hour</TableCell>
                                    <TableCell align="right">Close Date</TableCell>
                                    <TableCell align="right">Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* Project rows will be dynamically generated here */}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <p><Link>Create New Project <ArrowRightAltIcon /></Link></p>
            </div>
    
            {/* Consultant Hours */}
            <div className="dashboardContent__consultantHours">
                <div className="dashboardContent__consultantHours--heading">
                    <h3>Consultant Hours</h3>
                </div>
                <div className="dashboardContent__consultantHours--main">
                    <TimerIcon />
                    <span>
                        <p>Track and manage the hours consultants spend on each project for accurate billing and resource management.</p>
                        <button>Manage Hours <ArrowRightAltIcon /></button>
                    </span>
                </div>
            </div>
    
            {/* Company News */}
            <div className="dashboardContent__news">
                <div className="dashboardContent__news--heading">
                    <h3>Company Newsfeed</h3>
                </div>
                <div className="dashboardContent__news--main">
                    <GroupWorkIcon />
                    <span>
                        <p>Stay informed with the latest company updates, project announcements, and industry news.</p>
                        <button>View Newsfeed</button>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default CompanyDashboardContent;
