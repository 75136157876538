import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useStateValue } from './StateProvider';
import { auth } from './firebase';
import { actionTypes } from './reducer';

function ProtectedRoute({ element, allowedUsers }) {
  const [{ user, accountType }, dispatch] = useStateValue();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      const localAccountType = localStorage.getItem('accountType');
      const personalInfo = localStorage.getItem('personalInfo');
      if (authUser) {
        // User is signed in, dispatch the user details
        dispatch({
          type: actionTypes.SET_USER,
          user: authUser,
          accountType: localAccountType,
          personalInfo: personalInfo,
        });
      } else {
        // User is signed out, clear the details
        dispatch({
          type: actionTypes.SET_USER,
          user: null,
          accountType: null,
          personalInfo: null,
        });
        localStorage.removeItem('accountType');
        localStorage.removeItem('personalInfo');
      }
      setIsLoading(false);
    });

    // Cleanup the subscription on unmount
    return () => unsubscribe();
  }, [dispatch]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (allowedUsers.includes(accountType)) {
    return element;
  } else {
    window.location.href = "https://www.covista.ru/"; // Redirect to the specified website
  }
}

export default ProtectedRoute;