import React from 'react';
import './DashboardContent.css';
import { Link } from 'react-router-dom';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button } from "@material-ui/core";


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});


function DashboardContent() {
    const classes = useStyles();

    const trackButtonClick = (buttonLabel) => {
        if (window.gtag) {
            window.gtag('event', 'click', {
                'event_category': 'Dashboard Actions',
                'event_label': buttonLabel
            });
        }
    };

    return (
        <div className="dashboardContent">
            {/* Card */}
            <div className="dashboardContent__card">
                <span>
                    <h4>Пригласите друга и получите до 1000 рублей</h4>
                    <p>Если вам нравится пользоваться COVISTA, поделитесь этим с друзьями и коллегами. Получите бонус после первого проекта приглашенного.</p>
                    <Button 
                        component={Link} 
                        to="/invitefriend" 
                        variant="contained" 
                        color="primary"
                        onClick={() => trackButtonClick('Invite Now')}
                    >
                        Пригласить сейчас
                    </Button>
                </span>
                <span>
                    <img src="https://www.f-cdn.com/assets/main/en/assets/banners/give-get/T104418-give-get-banner-present.png" alt="" />
                </span>
            </div>
    
            {/* Projects */}
            <div className="dashboardContent__projects">
                <div className="dashboardContent__projects--heading">
                    <h3>Недавние проекты</h3>
                </div>
                <div className="dashboardContent__projects--table">
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="простая таблица">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Название проекта / заявки</TableCell>
                                    <TableCell align="right">Поступления</TableCell>
                                    <TableCell align="right">Средняя ставка/час</TableCell>
                                    <TableCell align="right">Дата закрытия</TableCell>
                                    <TableCell align="right">Статус</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="left"> - </TableCell>
                                    <TableCell align="right"> - </TableCell>
                                    <TableCell align="right"> - </TableCell>
                                    <TableCell align="right"> - </TableCell>
                                    <TableCell align="right">Неактивно</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <Button 
                    component={Link} 
                    to="/myprojects" 
                    variant="contained" 
                    color="inherit"
                    onClick={() => trackButtonClick('Whatch all projects_1')}
                    >
                    Смотреть все <ArrowRightAltIcon />
                </Button>
            </div>
    
            {/* News */}
            <div className="dashboardContent__news">
                <div className="dashboardContent__news--heading">
                    <h3>Лента новостей</h3>
                </div>
                <div className="dashboardContent__news--main">
                    <img src="https://firebasestorage.googleapis.com/v0/b/consultingx-d3b3a.appspot.com/o/public%2F1.png?alt=media&token=427ac4ab-fd04-4f5c-a472-c8908dd31980" alt="" />
                    <span>
                        <p>
                            Здесь вы будете получать обновления по проектам и активности аккаунта. Выберите опцию ниже, чтобы начать.
                            </p>
                        <Button 
                        component={Link} 
                        to="/myprojects" 
                        variant="contained" 
                        color="inherit"
                        onClick={() => trackButtonClick('Whatch all projects_2')}
                        >
                        Просмотреть проекты
                        </Button>
                    </span>
                </div>
    
            </div>
    
        </div>
    )
}
    

export default DashboardContent
