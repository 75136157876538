import { doc, onSnapshot } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { ChatContext } from "../context/ChatContext";
import { db } from "../firebase";
import Message from "./Message";

const Messages = () => {
  const [messages, setMessages] = useState([]);
  const { data } = useContext(ChatContext);

  useEffect(() => {
    console.log("Messages useEffect triggered for chatId:", data.chatId);

    // Make sure you have a valid chatId before setting up the snapshot listener
    if (data.chatId && data.chatId !== "null") {
      const unSub = onSnapshot(doc(db, "chats", data.chatId), (docSnapshot) => {
        if (docSnapshot.exists()) {
          setMessages(docSnapshot.data().messages || []); // Fallback to empty array if messages are not present
        } else {
          console.log(`No such document for chatId: ${data.chatId}`);
          setMessages([]); // Reset messages if document does not exist
        }
      }, (error) => {
        console.error(`Error fetching document for chatId ${data.chatId}:`, error);
      });

      return () => {
        unSub(); // Unsubscribe from the listener when the component unmounts
      };
    } else {
      console.log('chatId is null or undefined, skipping snapshot setup.');
      setMessages([]); // Reset messages if chatId is not valid
    }
  }, [data.chatId]); // Depend on data.chatId to re-run effect

  return (
    <div className="messages">
      {messages.map((m) => (
        <Message message={m} key={m.id} />
      ))}
    </div>
  );
};

export default Messages;
