import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import './TransactionHistory.css'; // Ensure you create/update this CSS file

const TransactionHistory = ({ transactionHistory }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Paper elevation={3} className={isMobile ? 'transactionHistoryMobile' : ''}>
      <Typography variant={isMobile ? 'h5' : 'h3'}>История транзакций</Typography>
      <div className="tableWrapper"> {/* For scrollable table */}
        <Table size={isMobile ? 'small' : 'medium'}>
          <TableHead>
            <TableRow>
              <TableCell>Тип</TableCell>
              <TableCell align="right">Сумма</TableCell>
              <TableCell align="right">Число</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionHistory.map((transaction) => (
              <TableRow key={transaction.id}>
                <TableCell>{transaction.type}</TableCell>
                <TableCell align="right">{transaction.amount} Руб</TableCell>
                <TableCell align="right">{transaction.date}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};

export default TransactionHistory;
