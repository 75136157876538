import React from 'react';
import './Dashboard.css';
import CompanyDashboardContent from './CompanyDashboardContent';
import CompanyMenus from './CompanyMenus';
import CompanySidebar from './CompanySidebar';


function CompanyDashboard() {
    return (
        <div className="dashboard">
            <CompanyMenus />

            {/* Main */}
            <div className="dashboard__main">
                <CompanyDashboardContent />
                <CompanySidebar />
            </div>

        </div>
    )
}

export default CompanyDashboard
