import React, { useState } from 'react';
import './Sidebar.css';
import { Link } from 'react-router-dom';
import BusinessIcon from '@material-ui/icons/Business'; // Changed to represent business
import LinearProgress from '@material-ui/core/LinearProgress';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import PollIcon from '@material-ui/icons/Poll'; // Changed to represent polls
import { Button } from '@material-ui/core';
import { useStateValue } from '../StateProvider';

function CompanySidebar() {
    const [{ company, companyInfo }, dispatch] = useStateValue(); // Accessing company from global state
    const [value, setValue] = useState('');

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    // Use name from companyProfile if available, otherwise fallback to "Company"
    const companyName = companyInfo?.name || "Компания";

    return (
        <div className="sidebar">
            <div className="sidebar__cardone">
                <div className="sidebar__cardone--title">
                    <p>Welcome back,</p>
                    <h3>{companyName}</h3>
                </div>
                <div className="sidebar__cardone--progress">
                    <span>
                        <p>Setup Your Company Profile</p>
                        <p>{companyInfo?.setupProgress || '0'}%</p>
                    </span>
                    <LinearProgress variant="determinate" value={companyInfo?.setupProgress || 0} />
                </div>
                <div className="sidebar__cardone--projects">
                    <BusinessIcon />
                    <span>
                        <p><Link to="/manage-projects">Manage Projects</Link></p>
                    </span>
                </div>
                <div className="sidebar__cardone--balance">
                    <span>
                        <p>Account Balance</p>
                        <p><Link to="/finance">Finance Management <ArrowRightAltIcon /></Link></p>
                    </span>
                    <p>{companyInfo?.balance || '0'} Currency</p>
                </div>
            </div>
    
            <div className="sidebar__cardtwo">
                <span className="sidebar__cardtwo--title">
                    <h4>Company Earnings</h4>
                    <p><Link to="/earnings-insights">View Insights</Link></p>
                </span>
                <span>
                    <h5>Remaining</h5>
                    <p>{companyInfo?.remainingEarnings || '0'}/8</p>
                </span>
                <span>
                    <h5>Until Next Payout</h5>
                    <p>{companyInfo?.daysUntilPayout || '0'} days</p>
                </span>
                <span>
                    <h5>Bonus Multiplier</h5>
                    <p>{companyInfo?.bonusMultiplier || '1'}x</p>
                </span>
            </div>
    
            <div className="sidebar__cardthree">
                <h4>Company Polls</h4>
                <PollIcon />
                <span>
                    <p>Engage in company decision-making with quick polls.</p>
                    <Button variant="outlined" color="primary">View Polls</Button>
                </span>
            </div>
        </div>
    );
}

export default CompanySidebar;
