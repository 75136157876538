import React, { useContext, useEffect, useRef } from "react";
import { ChatContext } from '../context/ChatContext'; // Ensure this is the correct path
import { useStateValue } from "../StateProvider"; // Import useStateValue from StateProvider
import './style.css';

const Message = ({ message }) => {
  const [{ user, personalInfo }] = useStateValue(); // Using StateProvider to get the current user
  const { data } = useContext(ChatContext);
  const ref = useRef();

  const createdAt = message.createdAt?.toDate ? new Date(message.createdAt.toDate()) : new Date();

  useEffect(() => {
    console.log("Message prop:", message); // Log the message prop
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [message]);

  return (
    <div
      ref={ref}
      className={`message ${message.senderId === user.uid ? "owner" : ""}`}
    >
      <div className="messageInfo">
        <img
          src={
            message.senderId === user.uid
              ? personalInfo.photoURL // Use user.photoURL from StateProvider
              : data.user?.photoURL || 'defaultAvatarURL' // Provide default if photoURL is undefined
          }
          alt=""
        />
        <span>{createdAt.toLocaleTimeString()}</span>
      </div>
      <div className="messageContent">
        <p>{message.text}</p>
        {message.img && <img src={message.img} alt="" />}
      </div>
    </div>
  );
};

export default Message;
