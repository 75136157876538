import React from 'react';
import { Box, Typography, TextField, Chip } from '@material-ui/core';
import useStyles from './UserProfileStyles'; // Adjust the path as necessary

const SkillsSection = ({ skills, onFieldChange, editMode }) => {
    const classes = useStyles();

    // Ensure that the skills prop is always treated as an array
    const formattedSkills = Array.isArray(skills) ? skills : skills ? skills.split(', ') : [];

    return (
        <Box className={classes.root}>
            <Typography variant="h6" className={classes.profileHeader}>Навыки</Typography>
            {editMode ? (
                <TextField
                    name="skills"
                    variant="outlined"
                    value={formattedSkills.join(', ') || ''}
                    onChange={(e) => onFieldChange('skills')(e)}
                    fullWidth
                    className={classes.formControl}
                />
            ) : (
                formattedSkills.map((skill, index) => (
                    <Chip key={index} label={skill} className={classes.skillsChip} />
                ))
            )}
        </Box>
    );
};

export default React.memo(SkillsSection);


