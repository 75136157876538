import React from 'react'
import Sidebar from './Sidebar'
import Chat from './Chat'
import Menus from '../user_admin/Menus';
import './style.css';


const ChatWrapper = () => {
  return (
    <div className="dashboard">
      <Menus />
      <div className='home'>
        <div className="container">
          <Sidebar/>
          <Chat/>
        </div>
      </div>
    </div>
  )
}

export default ChatWrapper