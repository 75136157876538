import React, { useState } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { useStateValue } from "../StateProvider"; // Import useStateValue from StateProvider
import stringSimilarity from 'string-similarity'; // You might need to install this package
import levenshtein from 'fast-levenshtein';
import doubleMetaphone from 'talisman/phonetics/double-metaphone';
import './style.css';


const Search = () => {
  const [username, setUsername] = useState("");
  const [user, setUser] = useState(null);
  const [err, setErr] = useState(false);
  const [{ user: CurrentUser, personalInfo: CurrentPersonalInfo }] = useStateValue();
  
  const handleSearch = async () => {
    console.log(`Searching for user with username: "${username}"`); // Log the search input
    const names = username.trim().split(' ');
    let queries = [];

    if (names.length === 1) {
      let name = names[0];
      queries = [
        query(collection(db, "users"), where("personalInfo.firstName", "==", name)),
        query(collection(db, "users"), where("personalInfo.lastName", "==", name))
      ];
    } else if (names.length >= 2) {
      let [firstName, lastName] = names;
      queries = [
        query(
          collection(db, "users"),
          where("personalInfo.firstName", "==", firstName.trim()),
          where("personalInfo.lastName", "==", lastName.trim())
        )
      ];
    } else {
      console.error("Invalid input. Please provide a name.");
      setErr(true);
      return;
    }

    try {
      let highestScore = 0;
      let mostSimilarUser = null;
      console.log(`Searching for user with username: "${username}"`);
      for (const q of queries) {
        console.log(`Running query: ${JSON.stringify(q)}`); // Log the actual query
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          const userData = doc.data();
          const fullName = userData.personalInfo.firstName + ' ' + userData.personalInfo.lastName;
          let score = stringSimilarity.compareTwoStrings(username, fullName);

          // Double Metaphone
          const [userMeta1, userMeta2] = doubleMetaphone(fullName);
          const [inputMeta1, inputMeta2] = doubleMetaphone(username);
          if (userMeta1 === inputMeta1 || userMeta1 === inputMeta2 || userMeta2 === inputMeta1 || userMeta2 === inputMeta2) {
            score += 0.5; // Adjust the weight as necessary
          }

          // Levenshtein Distance
          const levDistance = levenshtein.get(username, fullName);
          score -= levDistance * 0.05; // Adjust the weight as necessary

          if (score > highestScore) {
            highestScore = score;
            mostSimilarUser = { uid: doc.id, ...userData };
          }
        });
      }

      if (highestScore > 0.1) {
        console.log("Most similar user found:", mostSimilarUser);
        setUser(mostSimilarUser);
      } else {
        console.log("No user found with the given username");
        setErr(true);
      }
    } catch (error) {
      console.error("Error during search:", error);
      setErr(true);
    }
  };

  const handleKey = (e) => {
    if (e.key === "Enter") {
      console.log("Enter key pressed, initiating search");
      handleSearch();
    }
  };  

  const handleSelect = async () => {
    console.log("handleSelect user.uid:", user?.uid);    
    const combinedId = CurrentUser.uid > user.uid
      ? `${CurrentUser.uid}${user.uid}`
      : `${user.uid}${CurrentUser.uid}`;

    console.log(`Combined ID for chat: ${combinedId}`);

    try {
      const res = await getDoc(doc(db, "chats", combinedId));

      if (!res.exists()) {
        console.log("Chat does not exist, creating new chat");
        await setDoc(doc(db, "chats", combinedId), { messages: [] });

        // Create or update userChats for CurrentUser
        await setDoc(doc(db, `userChats/${CurrentUser.uid}`), {
          [`${combinedId}.userInfo`]: {
            uid: user.uid,
            firstName: user.personalInfo.firstName,
            photoURL: user.personalInfo.photoURL,
          },
          [`${combinedId}.date`]: serverTimestamp(),
        }, { merge: true });

        // Create or update userChats for selectedUser
        await setDoc(doc(db, `userChats/${user.uid}`), {
          [`${combinedId}.userInfo`]: {
            uid: CurrentUser.uid,
            firstName: CurrentPersonalInfo.firstName,
            photoURL: CurrentPersonalInfo.photoURL,
          },
          [`${combinedId}.date`]: serverTimestamp(),
        }, { merge: true });

        console.log("Chat and userChats updated successfully");
      } else {
        console.log("Chat already exists");
      }
    } catch (error) {
      console.error("Error in handleSelect:", error);
    }
    setUser(null);
    setUsername(""); 
  };

  const handleInputChange = (e) => {
    console.log("Input change event triggered");
    setUsername(e.target.value);
  };
  

  return (
    <div className="search">
      <div className="searchForm">
        <input
          type="text"
          placeholder="Найти пользователя"
          onKeyDown={handleKey}
          // onChange={(e) => setUsername(e.target.value)}
          onChange={handleInputChange}
          value={username}
        />
      </div>
      {err && <span>Пользователь не найден</span>}
      {user && (
        <div className="userChat" onClick={handleSelect}>
          <img src={user.personalInfo.photoURL} alt="" />
          <div className="userChatInfo">
            <span>{user.personalInfo.firstName}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Search;