import React, { useState } from 'react';
import { db } from '../firebase';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import './CreateProject.css';
import CompanyMenus from '../company_admin/CompanyMenus';

function CreateProject() {
    const [formData, setFormData] = useState({
        creatorUserId: '', // Added
        name: '',
        description: '',
        description_full: '', // Added
        positionRequirement: '',
        length_days: '', // Changed from 'length' to 'length_days'
        // Add other fields as needed
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setError(null);
        const formattedDescriptionFull = formData.description_full.split('\n').join('\\n');


        try {
            await addDoc(collection(db, "projects"), {
                ...formData,
                description_full: formattedDescriptionFull,
                timestamp: Timestamp.fromDate(new Date()), // Use Firebase server timestamp
            });
            setFormData({
                creatorUserId: '',
                name: '',
                description: '',
                description_full: '',
                positionRequirement: '',
                length_days: '',
                // Reset other fields as needed
            });
        } catch (error) {
            setError(error.message);
        }

        setIsSubmitting(false);
    };

    return (
        <div className="dashboard">
            <CompanyMenus />
            <div className="createProject">
                <h2>Create a New Project</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="creatorUserId"
                        value={formData.creatorUserId}
                        onChange={handleChange}
                        placeholder="Creator User ID"
                        required
                    />
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Project Name"
                        required
                    />
                    <textarea
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        placeholder="Short Project Description"
                        required
                    />
                    <textarea
                        name="description_full"
                        value={formData.description_full}
                        onChange={handleChange}
                        placeholder="Full Project Description"
                        required
                    />
                    <input
                        type="text"
                        name="positionRequirement"
                        value={formData.positionRequirement}
                        onChange={handleChange}
                        placeholder="Position Requirement"
                        required
                    />
                    <input
                        type="text"
                        name="length_days"
                        value={formData.length_days}
                        onChange={handleChange}
                        placeholder="Project Length (days)"
                        required
                    />
                    <button type="submit" disabled={isSubmitting}>Create Project</button>
                    {error && <p className="error">{error}</p>}
                </form>
            </div>
        </div>
    );
}

export default CreateProject;
