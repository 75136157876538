import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Paper, Typography } from '@material-ui/core';
import DOMPurify from 'dompurify';

import './ProjectDetailsBanner.css';

function ProjectDetailsBanner({ projectId, onClose }) {
    const [projectDetails, setProjectDetails] = useState(null);

    useEffect(() => {
        async function fetchProjectDetails() {
            if (!projectId) return;

            const projectRef = doc(db, 'projects', projectId);
            const projectSnap = await getDoc(projectRef);

            if (projectSnap.exists()) {
                setProjectDetails(projectSnap.data());
            } else {
                console.log("No such project!");
            }
        }

        fetchProjectDetails();
    }, [projectId]);

    if (!projectDetails) return null;

    // Sanitize the fullDescription now that we know projectDetails is not null
    const formattedFullDescription = projectDetails.description_full.split('\\n').join('<br>');

    return (
        <Dialog open={Boolean(projectDetails)} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                {projectDetails.name}
                <IconButton onClick={onClose} style={{ position: 'absolute', right: '10px', top: '10px' }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Paper style={{ padding: '20px', margin: '10px 0', backgroundColor: '#f5f5f5' }}>
                    <Typography variant="body1" component="div" dangerouslySetInnerHTML={{ __html: formattedFullDescription }} />
                </Paper>
            </DialogContent>
        </Dialog>
    );
}

export default ProjectDetailsBanner;
