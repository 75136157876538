import React, { useState, useEffect, useRef } from 'react';
import { db, storage } from '../firebase'; // Import Firebase and Storage instances
import { doc, getDoc, setDoc, arrayUnion } from 'firebase/firestore';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

function ProposalUploadBanner({ projectId, userId, onClose, onApplied }) {
    const [file, setFile] = useState(null);
    const [project, setProject] = useState(null);
    const fileInputRef = useRef(null);


    useEffect(() => {
        const fetchProject = async () => {
            const docRef = doc(db, 'projects', projectId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setProject(docSnap.data());
            }
        };

        if (projectId) {
            fetchProject();
        }
    }, [projectId]);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleApplyClick = async () => {
        if (!file || !userId) {
            console.error("File or user ID missing");
            return;
        }
    
        try {
            // Define the path for the proposal in Firebase Storage
            const filePath = `project_proposals/${userId}/${projectId}_${file.name}`;
            const fileRef = storageRef(storage, filePath);
    
            // Upload the proposal file
            await uploadBytes(fileRef, file);
            const fileUrl = await getDownloadURL(fileRef);
    
            // Save proposal link to Firestore in the projectProposals collection
            const proposalRef = doc(db, 'projectProposals', projectId);
            await setDoc(proposalRef, {
                [userId]: {
                    proposal_link: fileUrl,
                    timestamp: new Date(),
                }
            }, { merge: true });
    
            // Save projectId in the UserProjects collection
            const userProjectRef = doc(db, 'userProjects', userId);
            await setDoc(userProjectRef, {
                applied_projects: arrayUnion(projectId)
            }, { merge: true });
    
            // Update UI after successful apply
            onApplied();
        } catch (error) {
            console.error("Error uploading file or updating Firestore: ", error);
        }
    };    
    

    return (
        <Dialog open={Boolean(project)} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                {project ? `Загрузить предложение для проекта ${project.name}` : 'Загрузить предложение'}
                <IconButton onClick={onClose} style={{ position: 'absolute', right: '10px', top: '10px' }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <input 
                    type="file" 
                    accept="application/pdf" 
                    onChange={handleFileChange} 
                    style={{ display: 'none' }} 
                    ref={fileInputRef}
                />
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={handleButtonClick}>
                    Выберите файл
                </Button>
                <Typography variant="body1" style={{ marginTop: '10px' }}>
                    {file ? file.name : "Файл не выбран"}
                </Typography>
                <p>Не знаете с чего начать? <a href="your_template_link" target="_blank" rel="noopener noreferrer">Скачайте образец по ссылке.</a></p>
                <Button 
                    variant="contained" 
                    color="secondary" 
                    onClick={handleApplyClick}
                    style={{ marginTop: '20px' }}>
                    Отправить
                </Button>
            </DialogContent>
        </Dialog>
    );
}

export default ProposalUploadBanner;
