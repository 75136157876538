import React, { useContext, useEffect, useState } from "react";
import { useStateValue } from "../StateProvider";
import { ChatContext } from '../context/ChatContext';
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import './style.css';

const Chats = () => {
  const [chats, setChats] = useState([]);
  const [{ user }] = useStateValue();
  const { dispatch } = useContext(ChatContext);

  const truncateString = (str, num) => {
    if (str.length > num) {
      return str.slice(0, num) + '...';
    } else {
      return str;
    }
  };

  const handleSelect = (userInfo) => {
    dispatch({ type: "CHANGE_USER", payload: userInfo });
    console.log("Chat selected:", userInfo);
  };
  

  useEffect(() => {
    if (!user.uid) {
      console.log("User ID not found, skipping chats fetch.");
      return;
    }

    const getChats = () => {
      console.log("Fetching chats for user ID:", user.uid);

      const unsub = onSnapshot(doc(db, "userChats", user.uid), (docSnapshot) => {
        if (docSnapshot.exists()) {
          const chatsData = docSnapshot.data();
          console.log("Raw chats data from Firestore:", chatsData);

          const formattedChats = Object.entries(chatsData)
            .filter(([key]) => key.includes('.userInfo')) 
            .map(([key, value]) => {
              const chatId = key.split('.')[0];
              const chatDate = chatsData[`${chatId}.date`]?.toDate();
              const lastMessageText = chatsData[chatId]?.lastMessage || 'No messages';
              const truncatedLastMessage = truncateString(lastMessageText, 30); // Truncate to 50 chars
              return {
                id: chatId,
                date: chatDate,
                lastMessage: truncatedLastMessage,
                userInfo: value
              };
            })
            .filter(chat => chat.date);

          console.log("Formatted chats data:", formattedChats);
          setChats(formattedChats);
        } else {
          console.log("No chats document found for this user.");
        }
      });

      return () => {
        unsub();
      };
    };

    getChats();
  }, [user.uid]);

  return (
    <div className="chats">
      {chats.length > 0 ? (
        chats.sort((a, b) => b.date - a.date)
          .map(({ id, userInfo, lastMessage }) => (
            <div
              className="userChat"
              key={id}
              onClick={() => handleSelect(userInfo)}
            >
              {userInfo && (
                <img
                  src={userInfo.photoURL}
                  alt=""
                  onError={(e) => {
                    e.target.src = 'https://firebasestorage.googleapis.com/v0/b/consultingx-d3b3a.appspot.com/o/public%2Fdefault_image_profile.jpg?alt=media&token=7b6f0990-203c-45e3-99bd-1dd357f656dc';
                  }}
                />
              )}

              <div className="userChatInfo">
                <span>{userInfo ? userInfo.firstName : 'Unknown'}</span>
                <p>{lastMessage}</p>
              </div>
            </div>
          ))
      ) : (
      <p style={{ color: 'white' }}>Активных чатов не найдено.</p>
      )}
    </div>
  );
};

export default Chats;