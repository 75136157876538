import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import FirebaseAuthComponent from './FirebaseAuthComponent';
import NameInput from './NameInput';
import RoleSelection from './RoleSelection';
import ExperienceLevel from './ExperienceLevel';
import SkillsSelection from './SkillsSelection';
import DailyRate from './DailyRate';
import LocationPreference from './LocationPreference';
import CvUploadComponent from './CvUploadComponent'; // Importing the new component
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Import for Firebase storage
import Button from '../SharedSignup/Button';
import '../SharedSignup/SharedSignup.css'

const sendGAEvent = ({ action, category, label, value }) => {
  if (window.gtag) {
    window.gtag('event', action, {
      'event_category': category,
      'event_label': label,
      'value': value
    });
  }
};

const FreelancerSignup = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [role, setRole] = useState('');
  const [experience, setExperience] = useState('');
  const [skills, setSkills] = useState([]);
  const [dailyRate, setDailyRate] = useState('');
  const [locationPreference, setLocationPreference] = useState('');
  const [cvFile, setCvFile] = useState(null); // State for the CV file
  const navigate = useNavigate();
  const location = useLocation();
  const step = parseInt(new URLSearchParams(location.search).get('step') || '1', 10);
  const steps = ['Я', 'Роль', 'Опыт', 'Навыки', 'Оплата', 'Локация', 'Резюме', 'В путь!'];


  // Navigate to the next step if the input is valid

  const navigateAndTrack = (isValid, currentStep, direction) => {
    if (isValid) {
      const nextStep = direction === 'next' ? currentStep + 1 : currentStep - 1;
      navigate(`?step=${nextStep}`);
      sendGAEvent({
        category: 'Signup Form',
        action: `Moved to step ${nextStep}`,
        label: `Step ${currentStep}`
      });
    } else {
      alert("Заполните данные");
    }
  };

  const validateAndNavigate = (isValid) => {
    if (isValid) {
      navigate(`?step=${step + 1}`);
    } else {
      alert("Заполните данные");
    }
  };

  const onSelectName = (updatedFirstName, updatedLastName) => {
    setFirstName(updatedFirstName);
    setLastName(updatedLastName);
  };

  const onSelectRole = (selectedRole) => {
    setRole(selectedRole);
  };

  const handleExperienceChange = (event) => {
    setExperience(event.target.value);
  };  

  const handleDailyRateChange = (event) => {
    setDailyRate(event.target.value);
  };

  const nextStep = () => {
    let inputIsValid = true;
  
    switch (step) {
      case 1: // Name Input step
        inputIsValid = firstName.trim().length > 0 && lastName.trim().length > 0;
        break;
      case 2: // Role Selection step
        inputIsValid = role.trim().length > 0;
        break;
      case 3: // Experience Level step
        inputIsValid = experience.trim().length > 0;
        break;
      case 4: // Skills Selection step
        inputIsValid = skills.length > 0;
        break;
      case 5: // Daily Rate step
        inputIsValid = dailyRate.trim().length > 0;
        break;
      case 6: // Location Preference step
        inputIsValid = locationPreference.trim().length > 0;
        break;
      case 7: // CV Upload step
        // Assuming the CV upload is not mandatory for navigation
        // Add validation logic here if needed
        break;
      case 8: // FirebaseAuthComponent step
        // Assuming no validation is needed for the final step
        break;
      default:
        inputIsValid = true;
    }
  
    if (inputIsValid) {
      const nextStepNumber = step + 1;
      navigate(`?step=${nextStepNumber}`);
      sendGAEvent({
        category: 'Signup Form',
        action: `Moved to step ${nextStepNumber}`,
        label: `Step ${step}`
      });
    } else {
      alert("Заполните данные");
    }
  };  

  const prevStep = () => {
    if (step > 1) {
      navigate(`?step=${step - 1}`);
    }
    navigateAndTrack(true, step, 'prev');
  };

  const db = getFirestore();

  const uploadCvToFirebase = async (userId) => {
    if (!cvFile) return null;
  
    const storage = getStorage();
    const storageRef = ref(storage, `user_cv/${userId}/${cvFile.name}`);
    await uploadBytes(storageRef, cvFile);
    return await getDownloadURL(storageRef);
  };

  const handleSignupSuccess = async (user) => {
    if (window.gtag) {
      window.gtag('set', 'user_properties', {
        user_id: user.uid,
      });
    }    
    try {
      const cvUrl = await uploadCvToFirebase(user.uid) || ""; 
  
      const userProfile = {
        accountType: "freelancer",
        personalInfo: {
          address: {
            city: "Город",
            country: "Страна",
          },
          firstName: firstName || "Имя",
          lastName: lastName || "Фамилия",
          photoURL: "path_to_storage.photo.jpg",
        },
        profile: {
          cvUrl: cvUrl,
          dailyRate: dailyRate || "XXX, руб",
          experienceLevel: experience || "Стаж",
          jobTitle: role || "Профессия",
        },
        locationPreference: locationPreference || "Предпочтение работы",
        skills: skills.length > 0 ? skills : ["Навыки"],
        settings: {
          newsletterSubscription: "Provided",
          privacySettings: {
            showProfile: true,
            enableNotifications: false,
            darkMode: false,
          }
        },
        timestampCreated: new Date().toISOString(),
      };
  
      await setDoc(doc(db, "users", user.uid), userProfile);
    } catch (error) {
      console.error("Signup error", error);
      alert("There was an issue with your signup. Please try again.");
    }
  };
  
  const handleSignupError = (error) => {
    console.error("Signup error", error);
  };

// Component steps rendering
const stepsComponents = {
  1: <NameInput 
        firstName={firstName} 
        lastName={lastName} 
        onNameChange={onSelectName} 
      />,
  2: <RoleSelection 
        selectedRole={role} 
        onSelectRole={setRole} // Set the role when changed
      />,
  3: <ExperienceLevel 
        experience={experience} 
        onExperienceChange={handleExperienceChange} 
      />,
  4: <SkillsSelection 
        selectedSkills={skills} 
        onSelectSkill={setSkills} 
        selectedRole={role} // Pass role as selectedRole
    />,
  5: <DailyRate 
        rate={dailyRate} 
        onRateChange={handleDailyRateChange} 
      />,
  6: <LocationPreference 
        selectedPreference={locationPreference} 
        onSelectPreference={(e) => setLocationPreference(e.target.value)} 
      />,
  7: <CvUploadComponent 
        onCvUpload={setCvFile} 
      />,
  8: <FirebaseAuthComponent 
        onSignupSuccess={handleSignupSuccess} 
        onSignupError={handleSignupError} 
      />,
};

  const renderStep = () => stepsComponents[step] || <div>Проверьте информацию</div>;

  return (
    <div className="freelancer-signup-content">
      <div className="stepper-container">
        <Stepper activeStep={step - 1} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
        
      {renderStep()}
      <div style={{ position: 'fixed', width: '100%', bottom: '20px', textAlign: 'center' }}>
        {/* Conditional rendering for the Back button */}
        {step > 1 && (
          <span style={{ marginRight: '10px' }}> {/* Add right margin to the Back button */}
            <Button onClick={prevStep} className="secondary">Назад</Button>
          </span>
        )}
        {/* Conditional rendering for the Next or FirebaseAuthComponent based on the step */}
        {step < 8 ? (
          <Button onClick={nextStep}>Далее</Button>
        ) : null}
      </div>
    </div>
  );  
}

export default FreelancerSignup;
