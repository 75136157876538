import React, { useState, useEffect } from 'react';
import Balance from './Balance'; // Update the path if necessary
import TransactionHistory from './TransactionHistory'; // Update the path if necessary
import PaymentDetails from './PaymentDetails'; // Update the path if necessary
import Menus from '../user_admin/Menus';
import MaterialTabs from './helpers/Tabs'; // Assuming the path is correct
import './DepositFunds.css';

const DepositFunds = () => {
  const [currentBalance, setCurrentBalance] = useState(0);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // Fetch current balance and transaction history
    setCurrentBalance(0);
    setTransactionHistory([
      { id: 1, type: 'Приход', amount: 0, date: '2023-01-01' },
      { id: 2, type: 'Снятие', amount: 0, date: '2023-02-01' },
      // ... more transactions
    ]);
    // Assume these values are being fetched from an API
  }, []);
  console.log('Current tab value:', tabValue); // Debugging line


  return (
    <div className="dashboard">
      <Menus />
      <div className="depositFundsContainer">
        <MaterialTabs>
          <div label="Баланс">
            <Balance currentBalance={currentBalance} />
          </div>
          <div label="История Транзакций">
            <TransactionHistory transactionHistory={transactionHistory} />
          </div>
          <div label="Платежные Данные">
            <PaymentDetails />
          </div>
        </MaterialTabs>
      </div>
    </div>
  );
};

export default DepositFunds;
