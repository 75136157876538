import React, { useState, useContext } from 'react';
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../firebase';
import { ChatContext } from '../context/ChatContext';
import { useStateValue } from '../StateProvider'; // Assuming this is the correct path
// import firebase from 'firebase/compat/app'; // Import firebase to use Timestamp
import { v4 as uuid } from "uuid";
import { Timestamp, serverTimestamp } from 'firebase/firestore';
import './style.css';


const Input = () => {
  const [text, setText] = useState("");
  const [{ user }] = useStateValue(); // Using StateProvider to get the current user
  const { data } = useContext(ChatContext);

  const handleSend = async () => {
    if (text.trim()) {
      try {
        // Create a new message object
        const newMessage = {
          id: uuid(),
          text,
          senderId: user.uid, // Assume user.uid is obtained from StateProvider
          date: Timestamp.now(), // Client-side timestamp
        };
  
        // Update the chat document with the new message
        await updateDoc(doc(db, "chats", data.chatId), {
          messages: arrayUnion(newMessage)
        });
  
        // Update the last message and date for the user chats
        const userChatUpdate = {
          [`${data.chatId}.lastMessage`]: newMessage.text,
          [`${data.chatId}.date`]: serverTimestamp()
        };
        await updateDoc(doc(db, "userChats", user.uid), userChatUpdate);
  
        setText(""); // Clear the input after sending
      } catch (error) {
        console.error("Error sending message:", error);
        // Handle error appropriately
      }
    }
  };  

  return (
    <div className="input">
      <input
        type="text"
        onChange={(e) => setText(e.target.value)}
        value={text}
      />
      <button onClick={handleSend}>Send</button>
    </div>
  );
};

export default Input;
