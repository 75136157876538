import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import DashboardContent from './DashboardContent';
import Menus from './Menus';
import Sidebar from './Sidebar';
import { useStateValue } from '../StateProvider';

function Dashboard() {
  // State to track window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [{ personalInfo }, dispatch] = useStateValue();

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      // Set window width to state
      setWindowWidth(window.innerWidth);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Ensure personalInfo is not null before checking isLoading
    if (personalInfo && personalInfo.isLoading) {
      console.log("Personal information is loading...");
    } else if (personalInfo) {
      // Check if personalInfo exists before logging it
      console.log("Personal information has been loaded:", personalInfo);
    }
}, [personalInfo]);

// For rendering, check if personalInfo is not null and isLoading is true
if (personalInfo && personalInfo.isLoading) {
    return <div>Loading...</div>;
}
  return (
    <div className="dashboard">
      <Menus />

      {/* Main content area */}
      <div className="dashboard__main">
        <DashboardContent />
        {/* Conditionally render Sidebar based on window width */}
        {windowWidth >= 768 && <Sidebar />}
      </div>
    </div>
  );
}

export default Dashboard;
