import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  AppBar, 
  Toolbar, 
  IconButton, 
  Drawer, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  Avatar, 
  Menu, 
  Button,
  MenuItem, 
  Fade 
} from '@material-ui/core';
import {
    Menu as MenuIcon,
    Home as HomeIcon,
    DesktopWindows as DesktopWindowsIcon,
    Sms as SmsIcon,
    NotificationsNone as NotificationsNoneIcon,
    Search as SearchIcon,
    ExpandMore as ExpandMoreIcon,
    Close as CloseIcon,
    CreditCard as CardIcon // Ensure the import is correctly spelled and matched
  } from '@material-ui/icons';

import { Link, useNavigate } from 'react-router-dom';
import { useStateValue } from '../StateProvider';
import { auth } from '../firebase';
import { actionTypes } from '../reducer';
import { getStorage, ref, getDownloadURL } from "firebase/storage";


// Add styles for your components
const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  appBar: {
    backgroundColor: '#0e1724',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  projectButton: {
    backgroundColor: '#fc8c14', // This sets the button color to orange
    '&:hover': {
      backgroundColor: '#e57c00', // This changes the button color on hover
    },
    color: 'white', // This sets the text color inside the button to white
    marginLeft: theme.spacing(5), // This adds some spacing to the left of the button
    marginRight: theme.spacing(10), // This adds some spacing to the left of the button
    [theme.breakpoints.down('sm')]: {
      display: 'none', // This hides the button on small screens (mobile)
    },
  },
  userFirstName: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
      color: 'white',
    },
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    width: 250,
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0,
  },
  desktopMenuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: { // Hide these menu items on smaller screens
      display: 'none',
    },
  },
  // Add a style for desktop navigation links
  desktopLinks: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      // Add space between menu items
      '& > *': {
        margin: theme.spacing(0, 5), // Adjust the spacing as needed
      },
    },
  },
  buttonIcon: {
    marginRight: theme.spacing(2), // Adjust the spacing as needed
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column', // Stack logo and name vertically
  },
}));

function Menus() {
  const classes = useStyles();
  const [{ user, personalInfo }, dispatch] = useStateValue();
  const [avatarUrl, setAvatarUrl] = useState("fallback_image_url");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    if (personalInfo?.photoURL) {
      const fetchImageURL = async (imageRefPath) => {
        const storage = getStorage();
        const imageRef = ref(storage, imageRefPath);
        try {
          const url = await getDownloadURL(imageRef);
          setAvatarUrl(url);
        } catch (error) {
          setAvatarUrl("fallback_image_url");
        }
      };

      fetchImageURL(personalInfo.photoURL);
    }
  }, [personalInfo?.photoURL]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    handleClose();
    navigate('/userprofile');
  };
  const handleSettingsClick = () => {
    handleClose();
    navigate('/user-settings');
  };

  const handleAuthentication = async () => {
    if (user) {
      await auth.signOut();
      dispatch({
        type: actionTypes.SET_USER,
        user: null,
        accountType: null,
        personalInfo: null,
      });
      localStorage.removeItem('accountType');
      navigate("/login");
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const trackDesktopMenuItemClick = (menuItemLabel) => {
    if (window.gtag) {
      window.gtag('event', 'click', {
        'event_category': 'Navigation',
        'event_label': `Desktop Menu: ${menuItemLabel}`
      });
    }
  };
  
  const trackDrawerItemClick = (menuItemLabel) => {
    if (window.gtag) {
      window.gtag('event', 'click', {
        'event_category': 'Navigation',
        'event_label': `Drawer Menu: ${menuItemLabel}`
      });
    }
  };

    const desktopMenuItems = (
      <div className={classes.desktopLinks}>
        <Button color="inherit" component={Link} to="/dashboard" startIcon={<HomeIcon />} onClick={() => trackDesktopMenuItemClick('Home')}>
          Домой
        </Button>
        <Button color="inherit" component={Link} to="/myprojects" startIcon={<DesktopWindowsIcon />} onClick={() => trackDesktopMenuItemClick('My Projects')}>
          Мои Проекты
        </Button>
        <Button color="inherit" component={Link} to="/chat" startIcon={<SmsIcon />} onClick={() => trackDesktopMenuItemClick('Messages')}>
          Сообщения
        </Button>
        <Button color="inherit" component={Link} to="" startIcon={<NotificationsNoneIcon />} onClick={() => trackDesktopMenuItemClick('Notifications')}>
          Уведомления
        </Button>
      </div>
    );

  // Drawer with onClick Tracking
  const drawer = (
    <div>
      <IconButton onClick={handleDrawerToggle} className={classes.closeMenuButton}>
        <CloseIcon />
      </IconButton>
      <List>
        <ListItem button component={Link} to="/dashboard" onClick={() => trackDrawerItemClick('Dashboard')}>
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <ListItemText primary="Домой" />
        </ListItem>
        <ListItem button component={Link} to="/projects-list" onClick={() => trackDrawerItemClick('Find Project')}>
          <ListItemIcon><SearchIcon /></ListItemIcon>
          <ListItemText primary="Найти проект" />
        </ListItem>      
        <ListItem button component={Link} to="/myprojects" onClick={() => trackDrawerItemClick('My Projects')}>
          <ListItemIcon><DesktopWindowsIcon /></ListItemIcon>
          <ListItemText primary="Мои Проекты" />
        </ListItem>
        <ListItem button component={Link} to="/chat" onClick={() => trackDrawerItemClick('Messages')}>
          <ListItemIcon><SmsIcon /></ListItemIcon>
          <ListItemText primary="Сообщения" />
        </ListItem>
        <ListItem button component={Link} to="/deposit-funds" onClick={() => trackDrawerItemClick('Payments')}>
          <ListItemIcon><CardIcon /></ListItemIcon>
          <ListItemText primary="Платежи" />
        </ListItem>      
      </List>
    </div>
  );


  return (
    <div className="menus">
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          {/* Hamburger Menu Icon */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          
          {/* Logo */}
          <Link to="/dashboard" className={classes.title}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/consultingx-d3b3a.appspot.com/o/public%2F2.png?alt=media&token=5ecf2548-8305-4c93-8b5f-dc09ab89d259"
              alt="logo"
              style={{ width: '70px', height: 'auto' }}
            />
          </Link>
          
          {/* Desktop Menu Items */}
          {desktopMenuItems}
          
          {/* Spacer */}
          <div className={classes.grow} />

          {/* Add the "Найти проект" button */}
          <Button variant="contained" className={classes.projectButton} component={Link} to="/projects-list">
            Найти проект
          </Button>
          
          {/* User Info and Avatar */}
          <div className={classes.userSection}>
            {/* User avatar */}
            <Avatar src={avatarUrl} />
            {/* User's First Name below the photo */}
            <span>{personalInfo?.firstName}</span>
          </div>
  
          {/* User settings icon */}
          <IconButton
            edge="end"
            aria-controls="fade-menu"
            aria-haspopup="true"
            onClick={handleClick}
            color="inherit"
          >
            <ExpandMoreIcon />
          </IconButton>
          
          {/* User settings menu */}
          <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={handleProfileClick}>Профиль</MenuItem>
            <MenuItem onClick={handleSettingsClick}>Настройки</MenuItem>
            <MenuItem onClick={handleAuthentication}>Выйти</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{ paper: classes.drawerPaper }}
          ModalProps={{ keepMounted: true }}
        >
          {drawer}
        </Drawer>
      </nav>
    </div>
  );  
}


export default Menus;
