export const roleToSkillsMap = {
    'Consultant': ["Consultative Selling", "Client Relationship Management", "Business Case Development", "Market Analysis", "Risk Assessment", "Digital Transformation", "Stakeholder Management"],
    'Designer': ["Graphic Design", "UI/UX Design", "Creativity", "Brand Management", "Digital Marketing", "Adobe Creative Suite", "Attention to Detail"],
    'Marketing': ["Marketing Strategy", "Brand Management", "Digital Marketing", "SEO", "SEM", "Social Media Management", "Content Creation"],
    'Engineer': ["Problem-Solving", "Technical Design", "Project Management", "Innovation Management", "Quality Assurance", "Attention to Detail", "Teamwork"],
    'Product Manager': ["Project Management", "Product Management", "Market Analysis", "Strategic Planning", "Leadership", "Negotiation", "Customer Service"],
    'Data Scientist': ["Python", "R", "Machine Learning", "Data Analysis", "Statistical Analysis", "Data Visualization", "SQL"],
    'Sales': ["Consultative Selling", "Sales Expertise", "Negotiation", "Customer Service", "Client Relationship Management", "Persuasion", "Market Analysis"],
    'IT Consultant': ["Technical Support", "IT Strategy", "Project Management", "Consultative Selling", "Problem-Solving", "Client Relationship Management", "IT Security"],
    'Business Analyst': ["Data Analysis", "Problem-Solving", "Business Intelligence", "SQL", "Project Management", "Reporting Skills", "Communication"],
    'Systems Analyst': ["Systems Integration", "Technical Support", "Problem-Solving", "IT Strategy", "Project Management", "Communication", "Data Analysis"],
    'Project Manager': ["Project Management", "Leadership", "Risk Management", "Communication", "Teamwork", "Time Management", "Problem-Solving"],
    'UI/UX Designer': ["UI/UX Design", "Graphic Design", "Creativity", "Web Development", "User Research", "Attention to Detail", "Adobe Creative Suite"],
    'Web Developer': ["JavaScript", "HTML", "CSS", "React.js", "Web Development", "Problem-Solving", "SQL"],
    'Software Developer': ["Java", "Python", "C++", "Software Development", "Problem-Solving", "Teamwork", "Software Architecture"],
    'Quality Assurance Analyst': ["Quality Assurance", "Attention to Detail", "Problem-Solving", "Communication", "Teamwork", "Software Testing", "Project Management"],
    'DevOps Engineer': ["DevOps Practices", "Automation Tools", "Cloud Computing", "Problem-Solving", "Scripting", "Linux", "CI/CD"],
    'Network Administrator': ["Network Management", "Problem-Solving", "Technical Support", "Cisco Technologies", "Network Security", "Communication", "IT Security"],
    'Database Administrator': ["SQL", "Database Management", "Problem-Solving", "Oracle", "MySQL", "Data Warehousing", "Communication"],
    'Cloud Architect': ["Cloud Computing", "AWS", "Azure", "Solution Design", "Problem-Solving", "Project Management", "Automation Tools"],
    'Security Analyst': ["Cybersecurity", "Network Security", "Ethical Hacking", "Incident Response", "Vulnerability Assessment", "Information Security Management", "Forensics Analysis"],
    'Data Analyst': ["Data Analysis", "SQL", "Python", "Statistical Analysis", "Data Visualization", "R", "Excel"],
    'Machine Learning Engineer': ["Python", "Machine Learning", "Deep Learning", "R", "Data Science", "TensorFlow", "Statistical Analysis"],
    'CRM Developer': ["CRM Tools", "JavaScript", "SQL", "Customer Service", "Software Development", "Problem-Solving", "Data Analysis"],
    'ERP Consultant': ["ERP Systems", "Business Process Improvement", "Project Management", "Consultative Selling", "Problem-Solving", "SQL", "Communication"],
    'Technical Support Specialist': ["Technical Support", "Problem-Solving", "Customer Service", "IT Security", "Network Management", "Communication", "Troubleshooting"],
    'Application Developer': ["Software Development", "Java", "Python", "Problem-Solving", "SQL", "Agile Practices", "Software Testing"],
    'Mobile Developer': ["Mobile Development", "Java", "Swift", "Android Development", "iOS Development", "UI/UX Design", "Problem-Solving"],
    'Front-end Developer': ["JavaScript", "HTML", "CSS", "React.js", "Web Development", "UI/UX Design", "Responsive Design"],
    'Back-end Developer': ["Java", "Python", "Node.js", "Database Management", "API Development", "Problem-Solving", "SQL"],
    'Full Stack Developer': ["JavaScript", "HTML", "CSS", "Node.js", "React.js", "SQL", "Web Development"],
    'Software Architect': ["Software Architecture", "Design Patterns", "Java", "C++", "Problem-Solving", "Team Leadership", "Communication"],
    'Data Engineer': ["Python", "SQL", "Data Warehousing", "ETL", "Big Data Technologies", "Data Modeling", "Cloud Computing"],
    'Data Warehouse Specialist': ["Data Warehousing", "ETL", "SQL", "Big Data Technologies", "Data Modeling", "Business Intelligence", "Cloud Computing"],
    'Business Intelligence Analyst': ["Business Intelligence", "Data Analysis", "SQL", "Data Visualization", "Reporting Skills", "Tableau", "Communication"],
    'Infrastructure Engineer': ["Infrastructure Management", "Network Management", "Cloud Computing", "Virtualization", "Linux", "Automation Tools", "IT Security"],
    'SEO Specialist': ["SEO Optimization", "Digital Marketing", "Content Creation", "Web Analytics", "Keyword Research", "Google Analytics", "HTML"],
    'Social Media Consultant': ["Social Media Strategy", "Digital Marketing", "Content Creation", "Brand Management", "SEO", "Analytics", "Communication"],
    'Digital Marketing Consultant': ["Digital Marketing", "SEO", "SEM", "Content Creation", "Social Media Management", "Marketing Strategy", "Analytics"],
    'E-commerce Analyst': ["E-commerce Solutions", "Data Analysis", "Market Analysis", "Digital Marketing", "SEO", "Web Analytics", "User Experience"],
    'Cybersecurity Consultant': ["Cybersecurity", "Ethical Hacking", "Incident Response", "Information Security Management", "Network Security", "Risk Assessment", "Vulnerability Assessment"],
    'Penetration Tester': ["Ethical Hacking", "Penetration Testing", "Cybersecurity", "Network Security", "Vulnerability Assessment", "Incident Response", "Information Security Management"],
    'Systems Engineer': ["Systems Engineering", "Problem-Solving", "Project Management", "Technical Design", "Automation Tools", "IT Security", "Network Management"],
    'IT Director': ["IT Strategy", "Leadership", "Project Management", "Budgeting", "Technology Leadership", "IT Governance", "Stakeholder Management"],
    'Chief Technology Officer': ["Technology Leadership", "Innovation Management", "Strategic Planning", "IT Strategy", "Leadership", "Business Acumen", "Project Management"],
    'Chief Information Officer': ["IT Strategy", "Technology Leadership", "Business Acumen", "Strategic Planning", "Project Management", "Leadership", "Risk Management"],
    'Scrum Master': ["Agile Practices", "Scrum Methodology", "Teamwork", "Leadership", "Problem-Solving", "Communication", "Project Management"],
    'Agile Coach': ["Agile Practices", "Coaching", "Leadership", "Teamwork", "Problem-Solving", "Communication", "Project Management"],
    'IT Auditor': ["IT Auditing", "Risk Management", "Compliance Management", "Information Security Management", "Problem-Solving", "Communication", "Cybersecurity"],
    'Compliance Officer': ["Compliance Management", "Regulatory Compliance", "Risk Management", "Legal Knowledge", "Attention to Detail", "Communication", "Ethical Hacking"],
    'Enterprise Architect': ["Enterprise Architecture", "Solution Design", "Strategic Planning", "IT Strategy", "Project Management", "Business Acumen", "Technical Leadership"],
    'Solutions Architect': ["Solution Design", "Technical Leadership", "Project Management", "Problem-Solving", "Communication", "Cloud Computing", "Software Development"],
    'Integration Specialist': ["Systems Integration", "API Development", "Problem-Solving", "Project Management", "Technical Support", "SQL", "Software Development"],
    'Software Tester': ["Software Testing", "Quality Assurance", "Attention to Detail", "Problem-Solving", "Communication", "Automation Tools", "Agile Practices"],
    'Release Manager': ["Release Coordination", "Project Management", "Problem-Solving", "Communication", "DevOps Practices", "Quality Assurance", "Agile Practices"],
    'Systems Integrator': ["Systems Integration", "Technical Support", "Project Management", "Problem-Solving", "Network Management", "Communication", "IT Security"],
    'IT Project Coordinator': ["Project Management", "Communication", "Organizational Skills", "Problem-Solving", "Time Management", "Teamwork", "Technical Understanding"],
    'Technical Writer': ["Technical Writing", "Communication", "Attention to Detail", "Research Skills", "Problem-Solving", "Technical Knowledge", "Project Management"],
    'User Researcher': ["User Research", "UI/UX Design", "Communication", "Problem-Solving", "Data Analysis", "Creativity", "Psychology Knowledge"],
    'Content Strategist': ["Content Strategy", "SEO", "Communication", "Digital Marketing", "Creativity", "Problem-Solving", "Brand Management"],
    'IT Trainer': ["IT Training", "Communication", "Teaching Skills", "Technical Knowledge", "Problem-Solving", "Adaptability", "Curriculum Development"],
    'Helpdesk Analyst': ["Technical Support", "Problem-Solving", "Customer Service", "Communication", "IT Security", "Network Management", "Troubleshooting"],
    'Network Engineer': ["Network Management", "Problem-Solving", "Cisco Technologies", "Communication", "Network Security", "Technical Support", "IT Security"],
    'Site Reliability Engineer': ["Site Reliability", "Automation Tools", "Cloud Computing", "Problem-Solving", "Network Management", "Linux", "Scripting"],
    'Automation Engineer': ["Automation Tools", "Scripting", "Problem-Solving", "Python", "Robotics", "Software Development", "Systems Engineering"],
    'Cloud Consultant': ["Cloud Computing", "Consultative Selling", "Problem-Solving", "Project Management", "AWS/Azure/GCP", "Communication", "Technical Support"],
    'IT Vendor Manager': ["IT Vendor Relations", "Negotiation", "Contract Management", "Communication", "Problem-Solving", "Project Management", "Strategic Planning"],
    'IT Procurement Specialist': ["IT Procurement", "Contract Negotiation", "Supply Chain Management", "Problem-Solving", "Communication", "Project Management", "Budgeting"],
    'Blockchain Developer': ["Blockchain Development", "Programming Skills", "Cryptography", "Problem-Solving", "Smart Contracts", "Ethereum", "Distributed Systems"],
    'Game Developer': ["Game Development", "Programming Skills", "Graphic Design", "Creativity", "Problem-Solving", "3D Modeling", "AI Programming"],
    'VR/AR Developer': ["VR/AR Development", "Programming Skills", "3D Modeling", "Unity/Unreal Engine", "Creativity", "Graphic Design", "Problem-Solving"],
    'IoT Developer': ["IoT Solutions", "Programming Skills", "Embedded Systems", "Problem-Solving", "Python", "Wireless Communication", "Security Measures"],
    'Embedded Systems Engineer': ["Embedded Systems", "C/C++", "Microcontrollers", "Electronics", "Problem-Solving", "Robotics", "System Design"],
    'AI Research Scientist': ["AI Research", "Machine Learning", "Deep Learning", "Python", "Data Science", "Algorithm Development", "Statistical Analysis"],
    'Bioinformatics Consultant': ["Bioinformatics", "Genomics", "Data Analysis", "Python", "Statistical Analysis", "Machine Learning", "Research Skills"],
    'Health IT Specialist': ["Health IT", "Electronic Health Records", "Data Privacy", "IT Security", "Project Management", "Technical Support", "Compliance"],
    'Telecommunications Specialist': ["Telecommunications", "Network Management", "Wireless Communication", "Problem-Solving", "Technical Support", "Project Management", "Communication"],
    'Marketing Consultant': ["Marketing Strategy", "Market Analysis", "Brand Management", "Digital Marketing", "Communication", "Sales Expertise", "Creativity"],
    'Digital Marketing Consultant': ["Digital Marketing", "SEO", "Content Marketing", "Social Media Strategy", "Analytics", "Brand Management", "SEM"],
    'Social Media Marketing Consultant': ["Social Media Strategy", "Content Creation", "Brand Management", "Digital Marketing", "Analytics", "SEO", "Communication"],
    'Content Marketing Consultant': ["Content Marketing", "SEO", "Content Creation", "Digital Marketing", "Brand Management", "Writing Skills", "Analytics"],
    'SEO Consultant': ["SEO Optimization", "Digital Marketing", "Content Strategy", "Web Analytics", "Keyword Research", "Google Analytics", "Technical SEO"],
    'PPC Advertising Consultant': ["Pay-per-click", "Digital Marketing", "Google AdWords", "Analytics", "Campaign Management", "SEM", "Strategic Planning"],
    'Brand Consultant': ["Brand Management", "Marketing Strategy", "Market Analysis", "Creative Strategy", "Communication", "Public Relations", "Digital Marketing"],
    'Public Relations Consultant': ["Public Relations", "Media Relations", "Communication", "Brand Management", "Event Planning", "Crisis Management", "Writing Skills"],
    'Sales Consultant': ["Sales Expertise", "Consultative Selling", "Negotiation", "Customer Service", "Market Analysis", "Communication", "CRM Tools"],
    'Management Consultant': ["Strategic Planning", "Business Analysis", "Project Management", "Change Management", "Problem-Solving", "Communication", "Leadership"],
    'Financial Advisory Consultant': ["Financial Management", "Financial Analysis", "Risk Management", "Investment Strategies", "Regulatory Compliance", "Budgeting", "Accounting"],
    'Human Resources Consultant': ["Human Resources", "Employee Relations", "Recruitment", "Training and Development", "Performance Management", "Labor Laws", "Organizational Development"],
    'Compliance Consultant': ["Compliance Management", "Regulatory Compliance", "Risk Management", "Legal Knowledge", "Auditing", "Ethical Guidelines", "Policy Development"],
    'Strategy Consultant': ["Strategic Planning", "Business Analysis", "Market Analysis", "Problem-Solving", "Leadership", "Project Management", "Communication"],
    'Operations Consultant': ["Operational Excellence", "Process Improvement", "Project Management", "Supply Chain Management", "Problem-Solving", "Lean Management", "Quality Assurance"],
    'IT Strategy Consultant': ["IT Strategy", "Technology Leadership", "Digital Transformation", "Project Management", "Problem-Solving", "Business Analysis", "IT Governance"],
    'Customer Relationship Management Consultant': ["CRM Tools", "Customer Service", "Sales Expertise", "Communication", "Strategic Planning", "Marketing Strategy", "Analytics"],
    'Environmental Consultant': ["Environmental Science", "Regulatory Compliance", "Sustainability Strategies", "Project Management", "Risk Assessment", "Research Skills", "Communication"],
    'Energy Consultant': ["Energy Management", "Renewable Energy", "Project Management", "Regulatory Compliance", "Sustainability Strategies", "Risk Assessment", "Technical Knowledge"],
    'Sustainability Consultant': ["Sustainability Strategies", "Environmental Science", "Project Management", "Corporate Social Responsibility", "Regulatory Compliance", "Research Skills", "Communication"],
    'Healthcare Consultant': ["Healthcare Management", "Regulatory Compliance", "Project Management", "Quality Assurance", "Data Analysis", "Policy Development", "Problem-Solving"],
    'Educational Consultant': ["Curriculum Development", "Teaching Skills", "Educational Technology", "Strategic Planning", "Research Skills", "Leadership", "Communication"],
    'Hospitality Consultant': ["Hospitality Management", "Customer Service", "Business Analysis", "Marketing Strategy", "Event Planning", "Operational Excellence", "Problem-Solving"],
    'Travel Consultant': ["Travel Planning", "Customer Service", "Sales Expertise", "Geographical Knowledge", "Communication", "Marketing Strategy", "Negotiation"],
    'Real Estate Consultant': ["Real Estate Knowledge", "Market Analysis", "Negotiation", "Sales Expertise", "Regulatory Compliance", "Customer Service", "Communication"],
    'Construction Consultant': ["Construction Management", "Project Management", "Regulatory Compliance", "Risk Management", "Technical Knowledge", "Budgeting", "Problem-Solving"],
    'Legal Consultant': ["Legal Knowledge", "Regulatory Compliance", "Research Skills", "Problem-Solving", "Communication", "Negotiation", "Ethical Guidelines"],
    'Economic Consultant': ["Economic Analysis", "Statistical Analysis", "Data Analysis", "Research Skills", "Communication", "Policy Development", "Problem-Solving"],
    'Political Consultant': ["Political Science", "Campaign Management", "Strategic Planning", "Media Relations", "Research Skills", "Communication", "Public Speaking"],
    'Nonprofit Organization Consultant': ["Nonprofit Management", "Fundraising", "Strategic Planning", "Program Development", "Communication", "Grant Writing", "Leadership"],
    'Risk Management Consultant': ["Risk Management", "Financial Analysis", "Regulatory Compliance", "Project Management", "Problem-Solving", "Analytical Skills", "Communication"],
    'Security Consultant': ["Security Management", "Risk Assessment", "IT Security", "Physical Security", "Incident Response", "Regulatory Compliance", "Problem-Solving"],
    'Logistics Consultant': ["Supply Chain Management", "Logistics", "Operational Excellence", "Project Management", "Problem-Solving", "Inventory Management", "Analytical Skills"],
    'Startup Consultant': ["Business Development", "Strategic Planning", "Market Analysis", "Fundraising", "Entrepreneurship", "Problem-Solving", "Leadership"],
    'Founder': ["Entrepreneurship", "Leadership", "Strategic Planning", "Business Development", "Risk Management", "Innovation Management", "Networking Skills"],
    'Investor': ["Financial Analysis", "Market Analysis", "Risk Assessment", "Portfolio Management", "Negotiation", "Strategic Planning", "Communication"], 
    'Консультант': ['Консультационные продажи',
    'Управление клиентскими отношениями',
    'Разработка бизнес-кейсов',
    'Анализ рынка',
    'Оценка рисков',
    'Цифровая трансформация',
    'Управление заинтересованными сторонами'],
    'Дизайнер': ['Графический дизайн',
    'Дизайн UI/UX',
    'Творчество',
    'Управление брендом',
    'Цифровой маркетинг',
    'Adobe Creative Suite',
    'Внимание к деталям'],
    'Маркетинг': ['Маркетинговая стратегия',
    'Управление брендом',
    'Цифровой маркетинг',
    'SEO',
    'SEM',
    'Управление социальными медиа',
    'Создание контента'],
    'Инженер': ['Решение проблем',
    'Техническое проектирование',
    'Управление проектами',
    'Управление инновациями',
    'Обеспечение качества',
    'Внимание к деталям',
    'Командная работа'],
    'Руководитель продукта': ['Управление проектами',
    'Управление продуктом',
    'Анализ рынка',
    'Стратегическое планирование',
    'Лидерство',
    'Переговоры',
    'Обслуживание клиентов'],
    'Ученый по данным': ['Python',
    'R',
    'Машинное обучение',
    'Анализ данных',
    'Статистический анализ',
    'Визуализация данных',
    'SQL'],
    'Продажи': ['Консультационные продажи',
    'Экспертиза в продажах',
    'Переговоры',
    'Обслуживание клиентов',
    'Управление клиентскими отношениями',
    'Убеждение',
    'Анализ рынка'],
    'IT-консультант': ['Техническая поддержка',
    'IT-стратегия',
    'Управление проектами',
    'Консультационные продажи',
    'Решение проблем',
    'Управление клиентскими отношениями',
    'Информационная безопасность'],
    'Бизнес-аналитик': ['Анализ данных',
    'Решение проблем',
    'Бизнес-аналитика',
    'SQL',
    'Управление проектами',
    'Навыки составления отчетов',
    'Коммуникация'],
    'Системный аналитик': ['Интеграция систем',
    'Техническая поддержка',
    'Решение проблем',
    'IT-стратегия',
    'Управление проектами',
    'Коммуникация',
    'Анализ данных'],
    'Руководитель проекта': ['Управление проектами',
    'Лидерство',
    'Управление рисками',
    'Коммуникация',
    'Командная работа',
    'Управление временем',
    'Решение проблем'],
    'UI/UX-дизайнер': ['Дизайн UI/UX',
    'Графический дизайн',
    'Творчество',
    'Веб-разработка',
    'Исследование пользователей',
    'Внимание к деталям',
    'Adobe Creative Suite'],
    'Веб-разработчик': ['JavaScript',
    'HTML',
    'CSS',
    'React.js',
    'Веб-разработка',
    'Решение проблем',
    'SQL'],
    'Разработчик программного обеспечения': ['Java',
    'Python',
    'C++',
    'Разработка программного обеспечения',
    'Решение проблем',
    'Командная работа',
    'Архитектура программного обеспечения'],
    'Специалист по обеспечению качества': ['Обеспечение качества',
    'Внимание к деталям',
    'Решение проблем',
    'Коммуникация',
    'Командная работа',
    'Тестирование программного обеспечения',
    'Управление проектами'],
    'DevOps-инженер': ['Практики DevOps',
    'Инструменты автоматизации',
    'Облачные вычисления',
    'Решение проблем',
    'Scripting',
    'Linux',
    'CI/CD'],
    'Администратор сети': ['Управление сетями',
    'Решение проблем',
    'Техническая поддержка',
    'Cisco Technologies',
    'Сетевая безопасность',
    'Коммуникация',
    'Информационная безопасность'],
    'Администратор баз данных': ['SQL',
    'Управление базами данных',
    'Решение проблем',
    'Oracle',
    'MySQL',
    'Складирование данных',
    'Коммуникация'],
    'Облачный архитектор': ['Облачные вычисления',
    'AWS',
    'Azure',
    'Проектирование решений',
    'Решение проблем',
    'Управление проектами',
    'Инструменты автоматизации'],
    'Аналитик по безопасности': ['Кибербезопасность',
    'Сетевая безопасность',
    'Этичный хакинг',
    'Реагирование на инциденты',
    'Оценка уязвимостей',
    'Управление информационной безопасностью',
    'Криминалистический анализ'],
    'Аналитик данных': ['Анализ данных',
    'SQL',
    'Python',
    'Статистический анализ',
    'Визуализация данных',
    'R',
    'Excel'],
    'Инженер по машинному обучению': ['Python',
    'Машинное обучение',
    'Deep Learning',
    'R',
    'Data Science',
    'TensorFlow',
    'Статистический анализ'],
    'Разработчик CRM': ['Инструменты CRM',
    'JavaScript',
    'SQL',
    'Обслуживание клиентов',
    'Разработка программного обеспечения',
    'Решение проблем',
    'Анализ данных'],
    'Консультант по ERP': ['Системы ERP',
    'Business Process Improvement',
    'Управление проектами',
    'Консультационные продажи',
    'Решение проблем',
    'SQL',
    'Коммуникация'],
    'Специалист по технической поддержке': ['Техническая поддержка',
    'Решение проблем',
    'Обслуживание клиентов',
    'Информационная безопасность',
    'Управление сетями',
    'Коммуникация',
    'Troubleshooting'],
    'Разработчик приложений': ['Разработка программного обеспечения',
    'Java',
    'Python',
    'Решение проблем',
    'SQL',
    'Гибкие практики',
    'Тестирование программного обеспечения'],
    'Мобильный разработчик': ['Разработка мобильных приложений',
    'Java',
    'Swift',
    'Android Development',
    'iOS Development',
    'Дизайн UI/UX',
    'Решение проблем'],
    'Front-end разработчик': ['JavaScript',
    'HTML',
    'CSS',
    'React.js',
    'Веб-разработка',
    'Дизайн UI/UX',
    'Responsive Design'],
    'Back-end разработчик': ['Java',
    'Python',
    'Node.js',
    'Управление базами данных',
    'API Development',
    'Решение проблем',
    'SQL'],
    'Full Stack разработчик': ['JavaScript',
    'HTML',
    'CSS',
    'Node.js',
    'React.js',
    'SQL',
    'Веб-разработка'],
    'Архитектор программного обеспечения': ['Архитектура программного обеспечения',
    'Design Patterns',
    'Java',
    'C++',
    'Решение проблем',
    'Team Leadership',
    'Коммуникация'],
    'Инженер по данным': ['Python',
    'SQL',
    'Складирование данных',
    'ETL',
    'Big Data Technologies',
    'Data Modeling',
    'Облачные вычисления'],
    'Специалист по хранилищам данных': ['Складирование данных',
    'ETL',
    'SQL',
    'Big Data Technologies',
    'Data Modeling',
    'Бизнес-аналитика',
    'Облачные вычисления'],
    'Аналитик бизнес-интеллекта': ['Бизнес-аналитика',
    'Анализ данных',
    'SQL',
    'Визуализация данных',
    'Навыки составления отчетов',
    'Tableau',
    'Коммуникация'],
    'Инженер инфраструктуры': ['Управление инфраструктурой',
    'Управление сетями',
    'Облачные вычисления',
    'Virtualization',
    'Linux',
    'Инструменты автоматизации',
    'Информационная безопасность'],
    'SEO-специалист': ['SEO оптимизация',
    'Цифровой маркетинг',
    'Создание контента',
    'Web Analytics',
    'Keyword Research',
    'Google Analytics',
    'HTML'],
    'Консультант по социальным сетям': ['Стратегия в социальных медиа',
    'Цифровой маркетинг',
    'Создание контента',
    'Управление брендом',
    'SEO',
    'Аналитика',
    'Коммуникация'],
    'Консультант по цифровому маркетингу': ['Цифровой маркетинг',
    'SEO',
    'Content Marketing',
    'Стратегия в социальных медиа',
    'Аналитика',
    'Управление брендом',
    'SEM'],
    'Аналитик электронной коммерции': ['Решения для электронной коммерции',
    'Анализ данных',
    'Анализ рынка',
    'Цифровой маркетинг',
    'SEO',
    'Web Analytics',
    'User Experience'],
    'Консультант по кибербезопасности': ['Кибербезопасность',
    'Этичный хакинг',
    'Реагирование на инциденты',
    'Управление информационной безопасностью',
    'Сетевая безопасность',
    'Оценка рисков',
    'Оценка уязвимостей'],
    'Тестер на проникновение': ['Этичный хакинг',
    'Тестирование на проникновение',
    'Кибербезопасность',
    'Сетевая безопасность',
    'Оценка уязвимостей',
    'Реагирование на инциденты',
    'Управление информационной безопасностью'],
    'Системный инженер': ['Системное инженерство',
    'Решение проблем',
    'Управление проектами',
    'Техническое проектирование',
    'Инструменты автоматизации',
    'Информационная безопасность',
    'Управление сетями'],
    'IT-директор': ['IT-стратегия',
    'Лидерство',
    'Управление проектами',
    'Бюджетирование',
    'Технологическое лидерство',
    'IT-управление',
    'Управление заинтересованными сторонами'],
    'Технический директор': ['Технологическое лидерство',
    'Управление инновациями',
    'Стратегическое планирование',
    'IT-стратегия',
    'Лидерство',
    'Business Acumen',
    'Управление проектами'],
    'Главный информационный директор': ['IT-стратегия',
    'Технологическое лидерство',
    'Business Acumen',
    'Стратегическое планирование',
    'Управление проектами',
    'Лидерство',
    'Управление рисками'],
    'Scrum-мастер': ['Гибкие практики',
    'Методология Scrum',
    'Командная работа',
    'Лидерство',
    'Решение проблем',
    'Коммуникация',
    'Управление проектами'],
    'Agile-коуч': ['Гибкие практики',
    'Coaching',
    'Лидерство',
    'Командная работа',
    'Решение проблем',
    'Коммуникация',
    'Управление проектами'],
    'IT-аудитор': ['IT-аудит',
    'Управление рисками',
    'Управление соответствием',
    'Управление информационной безопасностью',
    'Решение проблем',
    'Коммуникация',
    'Кибербезопасность'],
    'Специалист по комплаенсу': ['Управление соответствием',
    'Соблюдение нормативных требований',
    'Управление рисками',
    'Legal Knowledge',
    'Внимание к деталям',
    'Коммуникация',
    'Этичный хакинг'],
    'Корпоративный архитектор': ['Архитектура предприятия',
    'Проектирование решений',
    'Стратегическое планирование',
    'IT-стратегия',
    'Управление проектами',
    'Business Acumen',
    'Technical Leadership'],
    'Архитектор решений': ['Проектирование решений',
    'Technical Leadership',
    'Управление проектами',
    'Решение проблем',
    'Коммуникация',
    'Облачные вычисления',
    'Разработка программного обеспечения'],
    'Специалист по интеграции': ['Интеграция систем',
    'API Development',
    'Решение проблем',
    'Управление проектами',
    'Техническая поддержка',
    'SQL',
    'Разработка программного обеспечения'],
    'Тестировщик программного обеспечения': ['Тестирование программного обеспечения',
    'Обеспечение качества',
    'Внимание к деталям',
    'Решение проблем',
    'Коммуникация',
    'Инструменты автоматизации',
    'Гибкие практики'],
    'Релиз-менеджер': ['Координация выпуска',
    'Управление проектами',
    'Решение проблем',
    'Коммуникация',
    'Практики DevOps',
    'Обеспечение качества',
    'Гибкие практики'],
    'Системный интегратор': ['Интеграция систем',
    'Техническая поддержка',
    'Управление проектами',
    'Решение проблем',
    'Управление сетями',
    'Коммуникация',
    'Информационная безопасность'],
    'Координатор IT-проектов': ['Управление проектами',
    'Коммуникация',
    'Organizational Skills',
    'Решение проблем',
    'Управление временем',
    'Командная работа',
    'Technical Understanding'],
    'Технический писатель': ['Техническое письмо',
    'Коммуникация',
    'Внимание к деталям',
    'Research Skills',
    'Решение проблем',
    'Technical Knowledge',
    'Управление проектами'],
    'Исследователь пользователей': ['Исследование пользователей',
    'Дизайн UI/UX',
    'Коммуникация',
    'Решение проблем',
    'Анализ данных',
    'Творчество',
    'Psychology Knowledge'],
    'Стратег по контенту': ['Стратегия контента',
    'SEO',
    'Коммуникация',
    'Цифровой маркетинг',
    'Творчество',
    'Решение проблем',
    'Управление брендом'],
    'IT-тренер': ['ИТ-обучение',
    'Коммуникация',
    'Teaching Skills',
    'Technical Knowledge',
    'Решение проблем',
    'Адаптивность',
    'Curriculum Development'],
    'Специалист по поддержке': ['Техническая поддержка',
    'Решение проблем',
    'Обслуживание клиентов',
    'Коммуникация',
    'Информационная безопасность',
    'Управление сетями',
    'Troubleshooting'],
    'Сетевой инженер': ['Управление сетями',
    'Решение проблем',
    'Cisco Technologies',
    'Коммуникация',
    'Сетевая безопасность',
    'Техническая поддержка',
    'Информационная безопасность'],
    'Инженер по надежности сайта': ['Надежность сайта',
    'Инструменты автоматизации',
    'Облачные вычисления',
    'Решение проблем',
    'Управление сетями',
    'Linux',
    'Scripting'],
    'Инженер по автоматизации': ['Инструменты автоматизации',
    'Scripting',
    'Решение проблем',
    'Python',
    'Robotics',
    'Разработка программного обеспечения',
    'Системное инженерство'],
    'Облачный консультант': ['Облачные вычисления',
    'Консультационные продажи',
    'Решение проблем',
    'Управление проектами',
    'AWS/Azure/GCP',
    'Коммуникация',
    'Техническая поддержка'],
    'Управляющий IT-поставщиками': ['Отношения с ИТ-поставщиками',
    'Переговоры',
    'Contract Management',
    'Коммуникация',
    'Решение проблем',
    'Управление проектами',
    'Стратегическое планирование'],
    'Специалист по закупкам IT': ['Закупки в сфере ИТ',
    'Переговоры по контракту',
    'Управление цепочками поставок',
    'Решение проблем',
    'Коммуникация',
    'Управление проектами',
    'Бюджетирование'],
    'Разработчик блокчейна': ['Разработка блокчейна',
    'Programming Skills',
    'Cryptography',
    'Решение проблем',
    'Smart Contracts',
    'Ethereum',
    'Distributed Systems'],
    'Разработчик игр': ['Разработка игр',
    'Programming Skills',
    'Графический дизайн',
    'Творчество',
    'Решение проблем',
    '3D Modeling',
    'AI Programming'],
    'VR/AR-разработчик': ['Разработка VR/AR',
    'Programming Skills',
    '3D Modeling',
    'Unity/Unreal Engine',
    'Творчество',
    'Графический дизайн',
    'Решение проблем'],
    'IoT-разработчик': ['Решения IoT',
    'Programming Skills',
    'Встроенные системы',
    'Решение проблем',
    'Python',
    'Wireless Communication',
    'Security Measures'],
    'Инженер встраиваемых систем': ['Встроенные системы',
    'C/C++',
    'Microcontrollers',
    'Electronics',
    'Решение проблем',
    'Robotics',
    'System Design'],
    'Исследователь в области искусственного интеллекта': ['Исследования в области ИИ',
    'Машинное обучение',
    'Deep Learning',
    'Python',
    'Data Science',
    'Algorithm Development',
    'Статистический анализ'],
    'Консультант по биоинформатике': ['Биоинформатика',
    'Genomics',
    'Анализ данных',
    'Python',
    'Статистический анализ',
    'Машинное обучение',
    'Research Skills'],
    'Специалист по IT в сфере здравоохранения': ['Медицинские информационные технологии',
    'Electronic Health Records',
    'Data Privacy',
    'Информационная безопасность',
    'Управление проектами',
    'Техническая поддержка',
    'Compliance'],
    'Специалист по телекоммуникациям': ['Телекоммуникации',
    'Управление сетями',
    'Wireless Communication',
    'Решение проблем',
    'Техническая поддержка',
    'Управление проектами',
    'Коммуникация'],
    'Консультант по маркетингу': ['Маркетинговая стратегия',
    'Анализ рынка',
    'Управление брендом',
    'Цифровой маркетинг',
    'Коммуникация',
    'Экспертиза в продажах',
    'Творчество'],
    'Консультант по маркетингу в социальных сетях': ['Стратегия в социальных медиа',
    'Создание контента',
    'Управление брендом',
    'Цифровой маркетинг',
    'Аналитика',
    'SEO',
    'Коммуникация'],
    'Консультант по контент-маркетингу': ['Content Marketing',
    'SEO',
    'Создание контента',
    'Цифровой маркетинг',
    'Управление брендом',
    'Writing Skills',
    'Аналитика'],
    'Консультант по поисковой оптимизации (SEO)': ['SEO оптимизация',
    'Цифровой маркетинг',
    'Стратегия контента',
    'Web Analytics',
    'Keyword Research',
    'Google Analytics',
    'Technical SEO'],
    'Консультант по контекстной рекламе (PPC)': ['Pay-per-click',
    'Цифровой маркетинг',
    'Google AdWords',
    'Аналитика',
    'Campaign Management',
    'SEM',
    'Стратегическое планирование'],
    'Бренд-консультант': ['Управление брендом',
    'Маркетинговая стратегия',
    'Анализ рынка',
    'Creative Strategy',
    'Коммуникация',
    'Public Relations',
    'Цифровой маркетинг'],
    'Специалист по связям с общественностью': ['Public Relations',
    'Media Relations',
    'Коммуникация',
    'Управление брендом',
    'Event Planning',
    'Crisis Management',
    'Writing Skills'],
    'Консультант по продажам': ['Экспертиза в продажах',
    'Консультационные продажи',
    'Переговоры',
    'Обслуживание клиентов',
    'Анализ рынка',
    'Коммуникация',
    'Инструменты CRM'],
    'Консультант по управлению': ['Стратегическое планирование',
    'Business Analysis',
    'Управление проектами',
    'Управление изменениями',
    'Решение проблем',
    'Коммуникация',
    'Лидерство'],
    'Финансовый консультант': ['Финансовое управление',
    'Financial Analysis',
    'Управление рисками',
    'Investment Strategies',
    'Соблюдение нормативных требований',
    'Бюджетирование',
    'Accounting'],
    'Консультант по человеческим ресурсам': ['Human Resources',
    'Employee Relations',
    'Recruitment',
    'Training and Development',
    'Performance Management',
    'Labor Laws',
    'Организационное развитие'],
    'Консультант по комплаенсу': ['Управление соответствием',
    'Соблюдение нормативных требований',
    'Управление рисками',
    'Legal Knowledge',
    'Auditing',
    'Ethical Guidelines',
    'Policy Development'],
    'Стратегический консультант': ['Стратегическое планирование',
    'Business Analysis',
    'Анализ рынка',
    'Решение проблем',
    'Лидерство',
    'Управление проектами',
    'Коммуникация'],
    'Консультант по операционной деятельности': ['Операционное совершенство',
    'Улучшение процессов',
    'Управление проектами',
    'Управление цепочками поставок',
    'Решение проблем',
    'Lean Management',
    'Обеспечение качества'],
    'Консультант по IT-стратегии': ['IT-стратегия',
    'Технологическое лидерство',
    'Цифровая трансформация',
    'Управление проектами',
    'Решение проблем',
    'Business Analysis',
    'IT-управление'],
    'Консультант по управлению взаимоотношениями с клиентами': ['Инструменты CRM',
    'Обслуживание клиентов',
    'Экспертиза в продажах',
    'Коммуникация',
    'Стратегическое планирование',
    'Маркетинговая стратегия',
    'Аналитика'],
    'Экологический консультант': ['Environmental Science',
    'Соблюдение нормативных требований',
    'Стратегии устойчивого развития',
    'Управление проектами',
    'Оценка рисков',
    'Research Skills',
    'Коммуникация'],
    'Энергетический консультант': ['Energy Management',
    'Renewable Energy',
    'Управление проектами',
    'Соблюдение нормативных требований',
    'Стратегии устойчивого развития',
    'Оценка рисков',
    'Technical Knowledge'],
    'Консультант по устойчивому развитию': ['Стратегии устойчивого развития',
    'Environmental Science',
    'Управление проектами',
    'Corporate Social Responsibility',
    'Соблюдение нормативных требований',
    'Research Skills',
    'Коммуникация'],
    'Консультант по здравоохранению': ['Healthcare Management',
    'Соблюдение нормативных требований',
    'Управление проектами',
    'Обеспечение качества',
    'Анализ данных',
    'Policy Development',
    'Решение проблем'],
    'Консультант по образованию': ['Curriculum Development',
    'Teaching Skills',
    'Educational Technology',
    'Стратегическое планирование',
    'Research Skills',
    'Лидерство',
    'Коммуникация'],
    'Консультант по гостиничному бизнесу': ['Hospitality Management',
    'Обслуживание клиентов',
    'Business Analysis',
    'Маркетинговая стратегия',
    'Event Planning',
    'Операционное совершенство',
    'Решение проблем'],
    'Консультант по туризму': ['Travel Planning',
    'Обслуживание клиентов',
    'Экспертиза в продажах',
    'Geographical Knowledge',
    'Коммуникация',
    'Маркетинговая стратегия',
    'Переговоры'],
    'Консультант по недвижимости': ['Real Estate Knowledge',
    'Анализ рынка',
    'Переговоры',
    'Экспертиза в продажах',
    'Соблюдение нормативных требований',
    'Обслуживание клиентов',
    'Коммуникация'],
    'Консультант по строительству': ['Construction Management',
    'Управление проектами',
    'Соблюдение нормативных требований',
    'Управление рисками',
    'Technical Knowledge',
    'Бюджетирование',
    'Решение проблем'],
    'Юридический консультант': ['Legal Knowledge',
    'Соблюдение нормативных требований',
    'Research Skills',
    'Решение проблем',
    'Коммуникация',
    'Переговоры',
    'Ethical Guidelines'],
    'Экономический консультант': ['Economic Analysis',
    'Статистический анализ',
    'Анализ данных',
    'Research Skills',
    'Коммуникация',
    'Policy Development',
    'Решение проблем'],
    'Политический консультант': ['Political Science',
    'Campaign Management',
    'Стратегическое планирование',
    'Media Relations',
    'Research Skills',
    'Коммуникация',
    'Публичные выступления'],
    'Консультант некоммерческих организаций': ['Nonprofit Management',
    'Fundraising',
    'Стратегическое планирование',
    'Program Development',
    'Коммуникация',
    'Grant Writing',
    'Лидерство'],
    'Консультант по управлению рисками': ['Управление рисками',
    'Financial Analysis',
    'Соблюдение нормативных требований',
    'Управление проектами',
    'Решение проблем',
    'Analytical Skills',
    'Коммуникация'],
    'Консультант по безопасности': ['Security Management',
    'Оценка рисков',
    'Информационная безопасность',
    'Physical Security',
    'Реагирование на инциденты',
    'Соблюдение нормативных требований',
    'Решение проблем'],
    'Консультант по логистике': ['Управление цепочками поставок',
    'Logistics',
    'Операционное совершенство',
    'Управление проектами',
    'Решение проблем',
    'Inventory Management',
    'Analytical Skills'],
    'Стартап-консультант': ['Развитие бизнеса',
    'Стратегическое планирование',
    'Анализ рынка',
    'Fundraising',
    'Entrepreneurship',
    'Решение проблем',
    'Лидерство'],
    'Основатель компании': ['Entrepreneurship',
    'Лидерство',
    'Стратегическое планирование',
    'Развитие бизнеса',
    'Управление рисками',
    'Управление инновациями',
    'Networking Skills'],
    'Инвестор': ['Financial Analysis',
    'Анализ рынка',
    'Оценка рисков',
    'Portfolio Management',
    'Переговоры',
    'Стратегическое планирование',
    'Коммуникация']
}