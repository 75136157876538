import React from 'react';
import './Project.css';


function Project({ project, onShowDetails, onShowUploadBanner, context }) {
    const { id, name, description, positionRequirement, length_days, companyProfileLink } = project;

    const sendAnalyticsEvent = (action, label) => {
        if (window.gtag) {
            window.gtag('event', action, {
                'event_category': 'Project Interaction',
                'event_label': label
            });
        }
    };

    // Updated renderApplyButton function to use new onShowUploadBanner
    const renderApplyButton = () => {
        if (context === 'applied') {
            return <button disabled className="applyButtonDisabled">Заявка отправлена</button>;
        } else if (context === 'active' || context === 'completed') {
            return null;
        } else {
            return (
                <button onClick={() => {
                    onShowUploadBanner(project.id);
                    sendAnalyticsEvent('Apply', `Project ID: ${project.id}`); // Send Apply event
                }} className="applyButton">
                    Подать заявку
                </button>
                );
        }
    };

  return (
    <div className="project">
        <h3>{name}</h3>
        <p>{description}</p>
        <p>Требования к кандидату: {positionRequirement}</p>
        <p>Продолжитель проекта (дней): {length_days}</p>
        <div className="projectActions">
                <button onClick={() => {
                    onShowDetails(id);
                    sendAnalyticsEvent('Details', `Project ID: ${id}`); // Send Details event
                }} className="detailsButton">
                    Подробнее
                </button>
                {renderApplyButton()}
            </div>
    </div>
);
}

export default Project;