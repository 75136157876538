// App.js
import React, { useEffect } from 'react';
import './App.css';
import Header from './Header';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Login from './Login';
import Signup from './signup/Signup';
import FreelancerSignup from './signup/FreelancerSignup/FreelancerSignup';
import CompanySignup from './signup/CompanySignup/CompanySignup';
import Dashboard from './user_admin/Dashboard';
import UserSettings from './user_admin/UserSettings/UserSettings';
import CompanyDashboard from './company_admin/CompanyDashboard';
import ProtectedRoute from './ProtectedRoute';
import UserProfileWrapper from './user_admin/UserProfile/UserProfileWrapper';
import ChatWrapper from './messages/ChatWrapper';
import ProjectsList from './projects/ProjectsList';
import CreateProject from './projects/CreateProject';
import MyProjects from './projects/MyProjects';
import InviteFriend from './marketing/InviteFriend'; // Ensure correct path
import EarningsAnalitics from './user_analitics/EarningsAnalitics'; // Ensure correct path
import DepositFunds from './funds/DepositFunds'; // Ensure correct path
import { StateProvider } from './StateProvider'; // Ensure correct path
import { ChatContextProvider } from './context/ChatContext'; // Ensure correct path
import Sidebar from './user_admin/Sidebar'; // Ensure correct path

const GA_MEASUREMENT_ID = "G-9D1Q8XH5V6"; // Replace with your actual GA4 Measurement ID

function App() {
  useEffect(() => {
    // Load the gtag.js script
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;
    script.async = true;
    document.head.appendChild(script);

    script.addEventListener('load', () => {
      // Define the global gtag function
      window.dataLayer = window.dataLayer || [];
      window.gtag = function() { window.dataLayer.push(arguments); }
      window.gtag('js', new Date());

      // Initialize the GA4 config
      window.gtag('config', GA_MEASUREMENT_ID, {
        'linker': {
          'domains': ['app.covista.ru', 'www.covista.ru']
        }
      });
    });
  }, []);

  return (
    <StateProvider>
      <ChatContextProvider>
        <Router>
          <Routes>
            <Route path="/*" element={<LayoutWithConditionalHeader />} />
          </Routes>
        </Router>
      </ChatContextProvider>
    </StateProvider>
  );
}


// Component to determine if the Header should be displayed
// Component to determine if the Header should be displayed
function LayoutWithConditionalHeader() {
  const location = useLocation();

  // Track page views when location changes
  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', GA_MEASUREMENT_ID, {
        'page_path': location.pathname + location.search,
      });
    }
  }, [location]);
  
  // Define routes that should not display the header
  const noHeaderRoutes = ['/dashboard', 
                          '/myprojects',
                          '/userprofile', 
                          '/companydashboard', 
                          '/projects-list',
                          '/create-project',
                          '/chat',
                          '/myprojects',
                          '/earningsanalitics',
                          '/invitefriend',
                          '/deposit-funds',
                          '/user-settings'
                        ];
  
  // Check if the current route is one of the routes that should not display the header
  const shouldDisplayHeader = !noHeaderRoutes.includes(location.pathname);

  return (
    <div className="app">
      {shouldDisplayHeader && <Header />}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/freelancer-signup" element={<FreelancerSignup />} />
        <Route path="/company-signup" element={<CompanySignup />} />
        <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} allowedUsers={['freelancer']} />} />
        <Route path="/companydashboard" element={<ProtectedRoute element={<CompanyDashboard />} allowedUsers={['company']} />} />
        <Route path="/userprofile" element={<ProtectedRoute element={<UserProfileWrapper />} allowedUsers={['freelancer']} />} />
        <Route path="/user-settings" element={<ProtectedRoute element={<UserSettings />} allowedUsers={['freelancer']} />} />
        <Route path="/chat" element={<ProtectedRoute element={<ChatWrapper />} allowedUsers={['freelancer', 'company']} />} />
        <Route path="/projects-list" element={<ProtectedRoute element={<ProjectsList />} allowedUsers={['freelancer']} />} />
        {/* Todo: fix permissions: */}
        <Route path="/create-project" element={<ProtectedRoute element={<CreateProject />} allowedUsers={['freelancer']} />} />
        <Route path="/myprojects" element={<ProtectedRoute element={<MyProjects />} allowedUsers={['freelancer']} />} />
        <Route path="/invitefriend" element={<ProtectedRoute element={<InviteFriend />} allowedUsers={['freelancer']} />} />
        <Route path="/earningsanalitics" element={<ProtectedRoute element={<EarningsAnalitics />} allowedUsers={['freelancer']} />} />
        <Route path="/deposit-funds" element={<ProtectedRoute element={<DepositFunds />} allowedUsers={['freelancer']} />} />
        <Route path="/sidebar" element={<ProtectedRoute element={<Sidebar />} allowedUsers={['freelancer']} />} />

        <Route path="/" element={ 
          <></>
        } />
      </Routes>
    </div>
  );
}

export default App;
