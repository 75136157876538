import React from 'react';
import { makeStyles, useTheme, Tabs, Tab, Box, Typography, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  // Add custom styles here if needed
  tabRoot: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 100, // Reduce the minimum width of the Tab on small screens
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {/* Change Typography to div or another suitable tag */}
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

function MaterialTabs({ children }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        variant={isMobile ? "scrollable" : "standard"}
        scrollButtons={isMobile ? "on" : "off"}
        aria-label="simple tabs example"
      >
        <Tab label="Баланс" {...a11yProps(0)} classes={{ root: classes.tabRoot }} />
        <Tab label="Транзакции" {...a11yProps(1)} classes={{ root: classes.tabRoot }} />
        <Tab label="Реквизиты" {...a11yProps(2)} classes={{ root: classes.tabRoot }} />
      </Tabs>
      {React.Children.map(children, (child, index) => (
        <div> {/* Wrap TabPanel in a container */}
          <TabPanel value={value} index={index}>
            {child}
          </TabPanel>
        </div>
      ))}
    </Box>
  );
};

export default MaterialTabs;