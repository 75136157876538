import React, { useState, useEffect } from 'react';
import { auth } from '../../firebase';
import UserProfileInfo from './UserProfileInfo'; // Adjust the path as necessary
import ProfileDetails from './ProfileDetails'; // Adjust the path as necessary
import LocationPreferences from './LocationPreferences'; // Adjust the path as necessary
import SkillsSection from './SkillsSection'; // Adjust the path as necessary
import { useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Typography, Paper } from '@material-ui/core';
import useStyles from './UserProfileStyles'; // Adjust the path as necessary
import { fetchUserProfile, updateUserProfile, uploadFileToFirebase } from './firebaseUtils';


function UserProfile() {
    const classes = useStyles();
    const navigate = useNavigate();
    const [userProfile, setUserProfile] = useState(null);
    const [editMode, setEditMode] = useState(false); // New state for edit mode
    const [isLoading, setIsLoading] = useState(true);
    const [updatedProfile, setUpdatedProfile] = useState({}); // State to hold updates

    useEffect(() => {
        const initUserProfile = async () => {
            const profile = await fetchUserProfile(auth.currentUser);
            if (profile) {
                setUserProfile(profile);
            } else {
                console.log("No such document!");
            }
            setIsLoading(false);
        };
    
        if (auth.currentUser) {
            initUserProfile();
        }
    }, []);

    const handleEdit = () => {
        setEditMode(true);
        setUpdatedProfile({ ...userProfile }); // Initialize updatedProfile with current userProfile
    };

    const handleSave = async () => {
        if (auth.currentUser) {
            const userId = auth.currentUser.uid;
            await updateUserProfile(userId, updatedProfile);
            setUserProfile(updatedProfile);
            setEditMode(false);
        }
    };    

    const handleChange = (field, subfield = null, subSubfield = null) => (e) => {
        const value = e.target.value;
    
        setUpdatedProfile(prev => {
            return subSubfield
                ? { ...prev, [field]: { ...prev[field], [subfield]: { ...(prev[field][subfield] || {}), [subSubfield]: value } } }
                : subfield
                    ? { ...prev, [field]: { ...prev[field], [subfield]: value } }
                    : { ...prev, [field]: value };
        });
    };    
  
    // This is the event handler for the file input
    const handlePhotoUpload = async (e) => {
        const file = e.target.files[0];
        const photoURL = await uploadFileToFirebase(file, auth.currentUser.uid, 'user_profile_pic');
        if (photoURL) {
            setUpdatedProfile(prev => ({
                ...prev,
                personalInfo: { ...prev.personalInfo, photoURL }
            }));
        }
    };
    
    const handleCvUpload = async (e) => {
        const file = e.target.files[0];
        const cvUrl = await uploadFileToFirebase(file, auth.currentUser.uid, 'user_cv');
        if (cvUrl) {
            setUpdatedProfile(prev => ({ ...prev, profile: { ...prev.profile, cvUrl } }));
        }
    };

    const handleBack = () => {
        setEditMode(false);
    };

    if (isLoading) {
        return (
            <Box className={classes.centeredBox}>
                <CircularProgress />
            </Box>
        );
    }

    if (!userProfile) {
        return (
            <Box className={classes.centeredBox}>
                <Typography variant="h6">User profile not found.</Typography>
            </Box>
        );
    }

    return (
        <Paper className={classes.outerBox}>
            <Box className={classes.root}>
                {editMode && (
                    <Button variant="contained" color="primary" onClick={handleBack} className={classes.backButton}>
                        Назад
                    </Button>
                )}
                <Paper className={classes.innerBox}>
                    <UserProfileInfo 
                        personalInfo={editMode ? updatedProfile.personalInfo : userProfile.personalInfo}
                        onPhotoUpload={handlePhotoUpload}
                        onFieldChange={handleChange}
                        editMode={editMode}
                    />

                    <ProfileDetails 
                        profile={editMode ? updatedProfile.profile : userProfile.profile}
                        onCvUpload={handleCvUpload}
                        onFieldChange={handleChange}
                        editMode={editMode}
                    />

                    <LocationPreferences 
                        locationPreference={editMode ? updatedProfile.locationPreference : userProfile.locationPreference}
                        onFieldChange={handleChange}
                        editMode={editMode}
                    />

                    <SkillsSection 
                        skills={editMode ? updatedProfile.skills : userProfile.skills}
                        onFieldChange={handleChange}
                        editMode={editMode}
                    />

                    {editMode ? (
                        <Button variant="contained" color="primary" onClick={handleSave} className={classes.button}>
                            Сохранить изменения
                        </Button>
                    ) : (
                        <Button variant="contained" color="primary" onClick={handleEdit} className={classes.button}>
                            Редактировать профиль
                        </Button>
                    )}
                </Paper>
            </Box>
        </Paper>
    );
}

export default UserProfile;