import React from 'react';
import { signOut } from "firebase/auth";
import { auth } from '../firebase'; // Ensure this is the correct path
import { useStateValue } from '../StateProvider'; // Import useStateValue from StateProvider
import './style.css';

const Navbar = () => {
  const [{ user, personalInfo }] = useStateValue(); // Destructure to get user and personalInfo

  // Using optional chaining to avoid errors if user or personalInfo is undefined
  console.log("User Photo URL:", personalInfo?.photoURL);
  console.log("Username:", personalInfo?.firstName);
  
  return (
    <div className='navbar'>
      <span className="logo">COVISTA</span>
      <div className="user">
        <img src={personalInfo?.photoURL} alt="User" />
      </div>
    </div>
  );
};

export default Navbar;

