import { auth, getFirestore, doc, getDoc, setDoc, storage } from '../../firebase'; // Add 'storage' to the import list
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

// Fetch User Profile
export const fetchUserProfile = async (currentUser) => {
    if (!currentUser) return null;

    const db = getFirestore();
    const userDocRef = doc(db, "users", currentUser.uid);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
        return userDocSnap.data();
    } else {
        console.log("No such document!");
        return null; // or a default profile object
    }
};

// Update User Profile
export const updateUserProfile = async (userId, updatedProfile) => {
    const db = getFirestore();
    const userDocRef = doc(db, "users", userId);
    await setDoc(userDocRef, updatedProfile, { merge: true });
};

// Upload File to Firebase (Generalized for any file type)
export const uploadFileToFirebase = async (file, userId, folderPath) => {
    if (!file) return null;

    const storageRef = ref(storage, `${folderPath}/${userId}/${file.name}`);
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
};
