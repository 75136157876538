import React, { useEffect, useState } from 'react';
import './Sidebar.css';
import { Link } from 'react-router-dom';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import LinearProgress from '@material-ui/core/LinearProgress';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { useStateValue } from '../StateProvider';

function Sidebar() {
    const [{ user, personalInfo }] = useStateValue(); // Accessing user from global state
    const [userDisplayName, setUserDisplayName] = useState("Гость");


    useEffect(() => {
        console.log("Sidebar useEffect triggered");
        console.log("Current personalInfo:", personalInfo);
    
        // Accessing the nested 'data' object
        if (personalInfo && !personalInfo.isLoading && personalInfo.firstName && personalInfo.lastName) {
            const displayName = personalInfo.firstName + " " + personalInfo.lastName;
            setUserDisplayName(displayName);
            console.log("Setting user display name:", displayName);
        } else {
            setUserDisplayName("Гость");
            console.log("Personal info not available or incomplete, setting user display name to default");
        }
    }, [personalInfo]);
    
    
    const trackLinkClick = (linkLabel) => {
        if (window.gtag) {
          window.gtag('event', 'click', {
            'event_category': 'Sidebar',
            'event_label': `Link: ${linkLabel}`
          });
        }
      };

    return (
        <div className="sidebar">
            <div className="sidebar__cardone">
                <div className="sidebar__cardone--title">
                    <p>С возвращением,</p>
                    <h3>{userDisplayName}</h3>
                </div>
                <div className="sidebar__cardone--progress">
                    <span>
                        <p>Настройте ваш аккаунт</p>
                        <p>60%</p>
                    </span>
                    <LinearProgress variant="determinate" value={60} />
                </div>
                <div className="sidebar__cardone--english">
                    <span>
                        <ContactMailIcon />
                        <p><Link to="/userprofile" onClick={() => trackLinkClick('UserProfile')}>
                            Заполните ваш аккаунт и повысьте шанс получения наиболее интересных проектов</Link></p>
                        <p></p>
                    </span>
                </div>
                <div className="sidebar__cardone--balance">
                    <span>
                        <p>Баланс аккаунта</p>
                        <p><Link to="/deposit-funds" onClick={() => trackLinkClick('DepositFunds')}>
                            Вывести средства <ArrowRightAltIcon /></Link></p>
                    </span>
                    <p>0 РУБ</p>
                </div>
            </div>
    
            <div className="sidebar__cardtwo">
                <span className="sidebar__cardtwo--title">
                    <h4>Мои доходы</h4>
                    <p><Link to="/earningsanalitics" onClick={() => trackLinkClick('ViewAnalitics')}>
                        Посмотреть аналитику</Link></p>
                </span>
                <span>
                    <h5>Ваш уровень</h5>
                    <p>1</p>
                </span>
                <span>
                    <h5>До следующей выплаты</h5>
                    <p>30 дней</p>
                </span>
                <span>
                    <h5>Повышающий коэффициент</h5>
                    <p>1x</p>
                </span>
            </div>
        </div>
    );
}

export default Sidebar;