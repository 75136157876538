import React from 'react';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import useStyles from './UserProfileStyles'; // Adjust the path as necessary

const LocationPreferences = ({ locationPreference, onFieldChange, editMode }) => {
    const classes = useStyles();

    // Sample options for the dropdown, replace with your actual options
    const locations = [
        { value: 'online', label: 'Удаленно' },
        { value: 'offline', label: 'Очно' },
    ];

    return (
        <Box className={classes.root}>
            <Typography variant="h6" className={classes.profileHeader}>Предпочтение работы</Typography>
            {editMode ? (
                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                    <InputLabel id="location-preference-label">Предпочтение работы</InputLabel>
                    <Select
                        labelId="location-preference-label"
                        id="location-preference"
                        value={locationPreference || ''}
                        onChange={onFieldChange('locationPreference')}
                        label="Предпочтение работы"
                    >
                        {locations.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ) : (
                <Typography variant="body1">{locationPreference}</Typography>
            )}
        </Box>
    );
};

export default React.memo(LocationPreferences);
