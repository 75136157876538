import React from 'react';
import {
  Paper,
  Typography,
  Card,
  CardContent,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import './Balance.css'; // Make sure to create this CSS file for additional styles

const Balance = ({ currentBalance }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    // <Paper elevation={3} className={isMobile ? 'balanceMobile' : ''}>
      <Card style={{ width: isMobile ? '70%' : 'auto' }}>
        <CardContent>
          <Typography variant={isMobile ? 'h6' : 'h5'}>Текущий Баланс</Typography>
          <Typography variant={isMobile ? 'h5' : 'h4'} color="primary">
            {currentBalance} Руб
          </Typography>
        </CardContent>
      </Card>
    // </Paper>
  );
};

export default Balance;