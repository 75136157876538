import React from 'react';
import UserProfile from './UserProfile'; // Adjust the path as necessary
import Menus from '../Menus'; // Adjust the path as necessary
import useStyles from './UserProfileStyles'; // Adjust the path as necessary

function UserProfileWrapper() {
    const classes = useStyles();
    
    return (
        <div className={classes.userProfileWrapper}>
            <Menus />
            <div className={classes.userProfileMain}>
                <UserProfile />
            </div>
        </div>
    );
}

export default UserProfileWrapper;
