import React, { useState, useEffect } from 'react';
import Project from './Project';
import { db } from '../firebase';
import { collection, query, where, doc, getDocs, getDoc, documentId } from 'firebase/firestore';
import { Link } from 'react-router-dom'
import MaterialTabs from './helpers/Tabs';
import { useStateValue } from '../StateProvider';
import ProjectDetailsBanner from './ProjectDetailsBanner'; 
import Menus from '../user_admin/Menus';
import './MyProjects.css';


function MyProjects() {
  const [{ user }] = useStateValue();
  const [activeProjects, setActiveProjects] = useState([]);
  const [completedProjects, setCompletedProjects] = useState([]);
  const [appliedProjects, setAppliedProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);


  // Function to handle showing project details
  const showDetails = (projectId) => {
    setSelectedProjectId(projectId);
  };

  // Function to hide project details
  const hideDetails = () => {
    setSelectedProjectId(null);
  };

  useEffect(() => {
    fetchUserProjects();
  }, [user]);

  const fetchProjectsDetails = async (projectIds) => {
    if (projectIds.length === 0) {
      return [];
    }

    const projectsRef = collection(db, 'projects');
    const q = query(projectsRef, where(documentId(), 'in', projectIds));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  };

  const fetchUserProjects = async () => {
    if (user && user.uid) {
      const userProjectsRef = doc(db, 'userProjects', user.uid);
      const docSnap = await getDoc(userProjectsRef);

      if (docSnap.exists()) {
        const userProjectsData = docSnap.data();
        const appliedProjectsDetails = await fetchProjectsDetails(userProjectsData.applied_projects || []);
        const activeProjectsDetails = await fetchProjectsDetails(userProjectsData.active_projects || []);
        const completedProjectsDetails = await fetchProjectsDetails(userProjectsData.completed_projects || []);

        setAppliedProjects(appliedProjectsDetails);
        setActiveProjects(activeProjectsDetails);
        setCompletedProjects(completedProjectsDetails);
      }
    }
  };

  const renderNoProjectsMessage = () => (
  <div className="noProjectsMessage">
    Здесь пока нет ваших проектов. 
    <p></p>
    Найдите проекты на вкладке  <Link to="/projects-list">Найти проекты</Link> или ожидайте приглашения через вкладку Уведомления.
  </div>
  );

  return (
    <div className="dashboard">
      <Menus />
      <div className="myProjectsPage">
        <MaterialTabs>
          <div label="Активные Проекты">
            {activeProjects.length > 0 ? (
              activeProjects.map(project => (
                <Project
                  key={project.id}
                  project={project}
                  context="active"
                  onShowDetails={showDetails} // Added onShowDetails prop
                />
              ))
            ) : (
              renderNoProjectsMessage()
            )}
          </div>
          <div label="Завершенные Проекты">
            {completedProjects.length > 0 ? (
              completedProjects.map(project => (
                <Project
                  key={project.id}
                  project={project}
                  context="completed"
                  onShowDetails={showDetails} // Added onShowDetails prop
                />
              ))
            ) : (
              renderNoProjectsMessage()
            )}
          </div>
          <div label="Поданные Заявки">
            {appliedProjects.length > 0 ? (
              appliedProjects.map(project => (
                <Project
                  key={project.id}
                  project={project}
                  context="applied"
                  onShowDetails={showDetails} // Added onShowDetails prop
                />
              ))
            ) : (
              renderNoProjectsMessage()
            )}
          </div>
        </MaterialTabs>
      </div>
      {selectedProjectId && (
        <ProjectDetailsBanner
          projectId={selectedProjectId}
          onClose={hideDetails}
        />
      )}
    </div>
  );
  
}

export default MyProjects;