import React, { useState } from 'react';
import { Button, FormControl, InputLabel, Input, Typography, FormHelperText } from '@material-ui/core';

const CvUploadComponent = ({ onCvUpload }) => {
  const [cvFile, setCvFile] = useState(null);
  const [isFileValid, setIsFileValid] = useState(true);
  const [isUploaded, setIsUploaded] = useState(false);

  const sendGAEvent = ({ action, category, label, value }) => {
    if (window.gtag) {
      window.gtag('event', action, {
        'event_category': category,
        'event_label': label,
        'value': value
      });
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setIsUploaded(false);
    if (file && file.type === 'application/pdf') {
      setCvFile(file);
      setIsFileValid(true);
      // Track the file selection
      sendGAEvent({
        category: 'CV Upload',
        action: 'Selected CV File',
        label: 'CV Selection'
      });
    } else {
      setCvFile(null);
      setIsFileValid(false);
      // Track the file selection error
      sendGAEvent({
        category: 'CV Upload',
        action: 'CV File Selection Error',
        label: 'Invalid File Type'
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (cvFile && isFileValid) {
      onCvUpload(cvFile);
      setIsUploaded(true);
      // Track the successful upload
      sendGAEvent({
        category: 'CV Upload',
        action: 'Uploaded CV',
        label: 'CV Upload Success'
      });
    }
    // If you want to track unsuccessful attempts as well, you can add another event here
  };

  return (
    <div className="card">
    <div style={{ textAlign: 'center' }}>
      <Typography variant="h5" style={{ marginBottom: '20px', marginTop: '20px' }}>Загрузите Ваше CV (резюме)</Typography>
      <form onSubmit={handleSubmit}>
        <FormControl error={!isFileValid} component="fieldset" style={{ marginBottom: '20px' }}>
          <InputLabel htmlFor="cvUpload">Только PDF</InputLabel>
          <Input 
            id="cvUpload"
            type="file"
            inputProps={{ accept: '.pdf' }}
            onChange={handleFileChange}
          />
          {!isFileValid && <FormHelperText>Пожалуйста загрузите PDF файл.</FormHelperText>}
        </FormControl>
        <Button 
          variant="contained" 
          color="primary" 
          type="submit" 
          disabled={!cvFile || !isFileValid}
        >
          {isUploaded ? 'Успешно загружено' : 'Загрузить'}
        </Button>
      </form>
    </div>
    </div>
  );
};

export default CvUploadComponent;