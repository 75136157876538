import React, { useState } from 'react';
import { createUserWithEmailAndPassword, sendEmailVerification, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import InputField from '../SharedSignup/InputField';
import Button from '../SharedSignup/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { auth } from '../../firebase';

const sendGAEvent = ({ action, category, label, value }) => {
  if (window.gtag) {
    window.gtag('event', action, {
      'event_category': category,
      'event_label': label,
      'value': value
    });
  }
};

const FirebaseAuthComponent = ({ onSignupSuccess, onSignupError }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isAgreed, setIsAgreed] = useState(false);
  const [error, setError] = useState('');
  const [showBanner, setShowBanner] = useState(false);  // State to control the banner
  const navigate = useNavigate();  

  const trackButtonEvent = (buttonName) => {
    sendGAEvent({
      category: 'User',
      action: `Clicked on ${buttonName} button`,
      label: 'Signup'
    });
  };

  const canSubmit = () => {
    return email.length > 0 && password.length > 0 && isAgreed;
  };

  function getRussianErrorMessage(errorCode) {
    switch (errorCode) {
      case 'auth/email-already-in-use':
        return 'Адрес электронной почты уже используется другим аккаунтом.';
      case 'auth/invalid-email':
        return 'Недействительный адрес электронной почты.';
      case 'auth/operation-not-allowed':
        return 'Операция не разрешена.';
      case 'auth/weak-password':
        return 'Слабый пароль.';
      // Add more error codes and messages as needed
      default:
        return 'Произошла неизвестная ошибка.';
    }
  }

  const createAccountWithEmail = async () => {
    trackButtonEvent('Email Signup');
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await sendEmailVerification(userCredential.user);
      onSignupSuccess(userCredential.user);

      setShowBanner(true);  // Show the banner on successful signup
      setTimeout(() => {
        setShowBanner(false); // Hide the banner after 6 seconds
        navigate('/login');    // Navigate to the login page
      }, 6000);

    } catch (error) {
      console.error("Error in createAccountWithEmail:", error.message);
      const russianErrorMessage = getRussianErrorMessage(error.code);
      setError(russianErrorMessage); // Set error message in Russian based on the error code
      onSignupError(error);
      // Track the failed account creation
      sendGAEvent({
        category: 'User',
        action: 'Failed Email Signup',
        label: error.code
      });
    }
  };

  // const signInWithGoogle = async () => {
  //   trackButtonEvent('Google Signin');
  //   if (!isAgreed) {
  //     setError("Пожалуйста примите условия Политики обработки персональных данных.");
  //     return;
  //   }
  //   try {
  //     const provider = new GoogleAuthProvider();
  //     const result = await signInWithPopup(auth, provider);
  //     onSignupSuccess(result.user);
  //   } catch (error) {
  //     console.error("Error in signInWithGoogle:", error.message);
  //     setError(error.message);
  //     onSignupError(error);
  //   }
  // };

  return (
    <div className="side-content">
      <div
        className="card"
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#fff',
          padding: '20px',
          borderRadius: '10px',
        }}
      >
        <h1 style={{ color: '#4285F4', fontSize: '24px' }}>Создать аккаунт</h1>
        <form onSubmit={(e) => e.preventDefault()}>
          <InputField
            label="Email"
            type="email"
            name="email"
            value={email}
            placeholder="Введите свой email"
            onChange={(e) => setEmail(e.target.value)}
            style={{
              width: '100%',
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '5px',
              marginBottom: '10px',
            }}
          />
          <InputField
            label="Пароль"
            type="password"
            name="password"
            value={password}
            placeholder="Введите пароль"
            onChange={(e) => setPassword(e.target.value)}
            style={{
              width: '100%',
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '5px',
              marginBottom: '10px',
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isAgreed}
                onChange={(e) => setIsAgreed(e.target.checked)}
                name="agreement"
                color="primary"
              />
            }
            label={
                <>
                  Я прочитал и принимаю{" "}
                  <a href="https://firebasestorage.googleapis.com/v0/b/consultingx-d3b3a.appspot.com/o/public%2FCovista_privacy_policy.pdf?alt=media&token=c9a1415e-1633-469d-9a1c-ffc13f6dee32" target="_blank" rel="noopener noreferrer">
                    Правила политики обработки персональных данных
                  </a>
                </>
            }
          />
          {error && (
            <div style={{ color: 'red', marginBottom: '10px' }}>{error}</div>
          )}
          <Button
            onClick={createAccountWithEmail}
            disabled={!canSubmit()}
            style={{
              backgroundColor: '#4285F4',
              color: 'white',
              padding: '10px',
              border: 'none',
              borderRadius: '5px',
              width: '100%',
              marginBottom: '10px',
            }}
          >
            Зарегистрироваться
          </Button>
          {showBanner && (
            <div className="verification-banner">
              Сообщение о подтверждении отправлено на ваш электронный адрес. Пожалуйста, проверьте вашу почту.
            </div>
          )}
          {/* <Button
            onClick={signInWithGoogle}
            disabled={!isAgreed}
            style={{
              backgroundColor: '#fff',
              color: '#4285F4',
              padding: '10px',
              border: '1px solid #4285F4',
              borderRadius: '5px',
              width: '100%',
              marginBottom: '10px',
            }}
          >
            <img
              src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
              alt="Google"
              style={{ width: '20px', marginRight: '10px' }}
            />
            Вход с Google
          </Button> */}
        </form>
      </div>
    </div>
  );  
};

export default FirebaseAuthComponent;
